import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Select,
    Spacer,
    Text,
    useToast,
} from '@chakra-ui/react';
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import PropTypes from 'prop-types';

import { EmployeeStatus } from '../../constants/employeeManagementConstant';
import EmployeeManagement from '../../service/employee-management';

function EmployeeEditModal({
    isOpen,
    onClose,
    employee,
    supervisor,
    partnerList,
    designationList,
    setDataLoaderOpen,
    setIsEmployeeUpdated,
}) {
    const MODAL_TITLE = 'Edit Employee info';
    const navigate = useNavigate();
    const toast = useToast();
    const [designation, setDesignation] = useState(employee.designation);
    const [status, setStatus] = useState(employee.status);
    const [supervisorName, setSupervisorName] = useState('');
    const [supervisorNameNew, setSupervisorNameNew] = useState('');
    const [partnerId, setPartnerId] = useState(employee.partnerId);
    const [partners, setPartners] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [supervisorsNameAndId, setSupervisorNameAndId] = useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
    });

    useEffect(() => {
        setSupervisorName(supervisor.name);
        setSupervisorNameNew('');
        setPartners(partnerList);
        setDesignations(designationList);
    }, []);

    // Fetching Employee & make pair array of {name, id}
    async function getSupervisorByNameHandler(name) {
        setSupervisorNameAndId([]);
        let uniqueSupervisorsNameAndId = new Set();

        // If the length of name is minimum 3, then will proceed for API call
        if (name.length >= 1) {
            try {
                const response = await EmployeeManagement.getEmployeesByName(
                    name
                );
                const result = response.content;
                for (let i = 0; i < result.length; i++) {
                    uniqueSupervisorsNameAndId.add({
                        name: result[i].name,
                        id: result[i].employeeId,
                    });
                }
                setSupervisorNameAndId([...uniqueSupervisorsNameAndId]);
            } catch (error) {
                toast({
                    title: 'Search fail',
                    description: error.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    }

    const editEnum = {
        all: 0,
        designationEdited: 1,
        statusEdited: 2,
        supervisorNameEdited: 3,
        partnerIdEdited: 4,
    };

    // onChangeHandler of each field
    function changeInput(option, value) {
        if (value == null) value = '';

        let inputDesignation = designation;
        let inputStatus = status;
        let inputSupervisorNameNew =
            supervisorNameNew === '' ? supervisorName : supervisorNameNew;
        let inputPartnerId = partnerId;

        if (option === editEnum.designationEdited) {
            inputDesignation = value;
        }
        if (option === editEnum.statusEdited) {
            inputStatus = value;
        }
        if (option === editEnum.supervisorNameEdited) {
            inputSupervisorNameNew = value;
        }
        if (option === editEnum.partnerIdEdited) {
            inputPartnerId = value;
        }

        setDesignation(inputDesignation);
        setStatus(inputStatus);
        setSupervisorName(inputSupervisorNameNew);
        setPartnerId(inputPartnerId);
    }

    // Update employee
    async function update(values) {
        let booleanFlagProceedForUpdate = true;
        // Editing the value of supervisor and partner Id
        if (supervisorsNameAndId.length === 0) {
            values.supervisorId = employee.supervisorId;
        } else {
            const supervisorNameIdPair = supervisorsNameAndId.find(
                supervisor => {
                    return supervisor.name === supervisorName;
                }
            );
            if (supervisorNameIdPair) {
                values.supervisorId = supervisorNameIdPair.id;
            } else {
                booleanFlagProceedForUpdate = false;
                toast({
                    title: 'Selection fail',
                    description: 'Please select a supervisor',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
        values.partnerId = partnerId;

        if (booleanFlagProceedForUpdate) {
            const payload = {
                employeeId: employee.employeeId,
                designation: designation,
                status: values.status,
                supervisorId: values.supervisorId,
                partnerId: values.partnerId,
            };
            onClose();
            setDataLoaderOpen(true);
            try {
                await EmployeeManagement.updateEmployee(payload);
                navigate(`/employee/${payload.employeeId}`);
                setIsEmployeeUpdated(true);
                setDataLoaderOpen(false);
                toast({
                    title: 'Successfully Updated',
                    description: 'Employee has been updated successfully',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            } catch (error) {
                setDataLoaderOpen(false);
                toast({
                    title: 'Update Fail',
                    description: error.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered={false}
            closeOnOverlayClick={false}
            bgColor="#EEE"
        >
            <ModalOverlay />
            <ModalContent
                size="lg"
                maxW={{ lg: '650px', base: '96%' }}
                bgColor="#FFF"
                color="#464646"
                align="center"
                justifyContent="center"
            >
                <ModalCloseButton />
                <ModalBody pt={15}>
                    <Box
                        align="left"
                        fontSize="24px"
                        lineHeight="28px"
                        ml={{ lg: '36px', md: '14px', sm: '10px', base: '0px' }}
                        mt="24px"
                        mb="35px"
                    >
                        <Text as="b">{MODAL_TITLE}</Text>
                    </Box>

                    <Flex direction="column" align="center">
                        <form onSubmit={handleSubmit(update)}>
                            <Flex direction="row">
                                <Flex direction="column">
                                    <Text
                                        color="#0077C0"
                                        fontSize="16px"
                                        fontWeight="700"
                                        align="left"
                                    >
                                        {employee.name}
                                    </Text>
                                    <Text color="#828282" align="left">
                                        {employee.designation}
                                    </Text>
                                </Flex>
                                <Spacer />
                                <Text color="#828282">
                                    {employee.employeeId}
                                </Text>
                            </Flex>

                            <Divider my={3} />

                            <FormControl>
                                <FormLabel
                                    fontSize="14px"
                                    htmlFor="designation"
                                    color="#464646"
                                >
                                    Designation
                                </FormLabel>
                                <Flex direction={{ lg: 'row', base: 'column' }}>
                                    <Flex
                                        direction={{
                                            lg: 'column',
                                            base: 'column',
                                        }}
                                        width={{ base: '96%', lg: '486px' }}
                                    >
                                        <Select
                                            {...register('designation')}
                                            border="1px"
                                            borderColor="#DDDDDD"
                                            color="gray.500"
                                            fontSize="14px"
                                            value={designation}
                                            isInvalid={errors.designation}
                                            _focus={{
                                                borderColor:
                                                    '#DDDDDD !important',
                                            }}
                                            onChange={e => {
                                                changeInput(
                                                    editEnum.designationEdited,
                                                    e.target.value
                                                );
                                                register(
                                                    'designation'
                                                ).onChange(e);
                                            }}
                                        >
                                            {designations.map(
                                                (value, index) => (
                                                    <option
                                                        value={value}
                                                        key={index}
                                                    >
                                                        {value}
                                                    </option>
                                                )
                                            )}
                                        </Select>
                                    </Flex>
                                </Flex>
                                {errors.designation && (
                                    <Text
                                        color="#E53E3E"
                                        mt={2}
                                        fontSize="14px"
                                        maxWidth={{ base: '96%', lg: '70%' }}
                                        status="error"
                                    >
                                        {errors.designation.message}
                                    </Text>
                                )}
                            </FormControl>

                            <FormControl mt={6}>
                                <FormLabel
                                    fontSize="14px"
                                    htmlFor="status"
                                    color="#464646"
                                >
                                    Status
                                </FormLabel>
                                <Flex direction={{ lg: 'row', base: 'column' }}>
                                    <Flex
                                        direction={{
                                            lg: 'column',
                                            base: 'column',
                                        }}
                                        width={{ base: '96%', lg: '486px' }}
                                    >
                                        <Select
                                            {...register('status')}
                                            border="1px"
                                            borderColor="#DDDDDD"
                                            color="gray.500"
                                            fontSize="14px"
                                            value={status}
                                            isInvalid={errors.status}
                                            _focus={{
                                                borderColor:
                                                    '#DDDDDD !important',
                                            }}
                                            onChange={e => {
                                                changeInput(
                                                    editEnum.statusEdited,
                                                    e.target.value
                                                );
                                                register('status').onChange(e);
                                            }}
                                        >
                                            {EmployeeStatus.map(
                                                (value, index) => (
                                                    <option
                                                        value={value}
                                                        key={index}
                                                    >
                                                        {value}
                                                    </option>
                                                )
                                            )}
                                        </Select>
                                    </Flex>
                                </Flex>
                                {errors.status && (
                                    <Text
                                        color="#E53E3E"
                                        mt={2}
                                        fontSize="14px"
                                        maxWidth={{ base: '96%', lg: '70%' }}
                                        status="error"
                                    >
                                        {errors.status.message}
                                    </Text>
                                )}
                            </FormControl>

                            <FormControl mt={6}>
                                <FormLabel
                                    fontSize="14px"
                                    htmlFor="supervisorId"
                                    color="#464646"
                                >
                                    Supervisor Name
                                </FormLabel>
                                <Flex direction={{ lg: 'row', base: 'column' }}>
                                    <Flex
                                        direction={{
                                            lg: 'column',
                                            base: 'column',
                                        }}
                                        width={{ base: '96%', lg: '486px' }}
                                    >
                                        <AutoComplete openOnFocus>
                                            <AutoCompleteInput
                                                variant="filled"
                                                {...register('supervisorId')}
                                                placeholder="Supervisor Name"
                                                border="1px"
                                                borderColor="#DDDDDD"
                                                bgColor="#FFFFFF"
                                                color="gray.500"
                                                fontSize="14px"
                                                autoComplete="off"
                                                value={
                                                    supervisorNameNew === ''
                                                        ? supervisorName
                                                        : supervisorNameNew
                                                }
                                                isInvalid={errors.supervisorId}
                                                _focus={{
                                                    borderColor:
                                                        '#DDDDDD !important',
                                                }}
                                                onChange={e => {
                                                    changeInput(
                                                        editEnum.supervisorNameEdited,
                                                        e.target.value
                                                    );
                                                    getSupervisorByNameHandler(
                                                        e.target.value
                                                    );
                                                    register(
                                                        'supervisorId'
                                                    ).onChange(e);
                                                }}
                                            />
                                            <AutoCompleteList>
                                                {supervisorsNameAndId.map(
                                                    (supervisor, s_id) => (
                                                        <AutoCompleteItem
                                                            key={`option-${s_id}`}
                                                            value={
                                                                supervisor.name
                                                            }
                                                            textTransform="capitalize"
                                                            onClick={() =>
                                                                setSupervisorName(
                                                                    supervisor.name
                                                                )
                                                            }
                                                        >
                                                            {supervisor.name}
                                                        </AutoCompleteItem>
                                                    )
                                                )}
                                            </AutoCompleteList>
                                        </AutoComplete>
                                    </Flex>
                                </Flex>
                                {errors.supervisorId && (
                                    <Text
                                        color="#E53E3E"
                                        mt={2}
                                        fontSize="14px"
                                        maxWidth={{ base: '96%', lg: '70%' }}
                                        status="error"
                                    >
                                        {errors.supervisorId.message}
                                    </Text>
                                )}
                            </FormControl>

                            <FormControl mt={6}>
                                <FormLabel
                                    fontSize="14px"
                                    htmlFor="partnerId"
                                    color="#464646"
                                >
                                    Partner Name
                                </FormLabel>
                                <Flex direction={{ lg: 'row', base: 'column' }}>
                                    <Flex
                                        direction={{
                                            lg: 'column',
                                            base: 'column',
                                        }}
                                        width={{ base: '96%', lg: '486px' }}
                                    >
                                        <Select
                                            {...register('partnerId')}
                                            border="1px"
                                            borderColor="#DDDDDD"
                                            color="gray.500"
                                            fontSize="14px"
                                            value={partnerId}
                                            isInvalid={errors.partnerId}
                                            _focus={{
                                                borderColor:
                                                    '#DDDDDD !important',
                                            }}
                                            onChange={e => {
                                                changeInput(
                                                    editEnum.partnerIdEdited,
                                                    e.target.value
                                                );
                                                //register('partnerId').onChange(e);
                                            }}
                                        >
                                            {partners.map(partner => (
                                                <option
                                                    value={partner.id}
                                                    key={partner.id}
                                                >
                                                    {partner.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </Flex>
                                </Flex>
                                {errors.partnerId && (
                                    <Text
                                        color="#E53E3E"
                                        mt={2}
                                        fontSize="14px"
                                        maxWidth={{ base: '96%', lg: '70%' }}
                                        status="error"
                                        align="left"
                                    >
                                        {errors.partnerId.message}
                                    </Text>
                                )}
                            </FormControl>

                            <Flex
                                direction="row"
                                mt={6}
                                mb="54px"
                                w={{ lg: '486px', base: '96%' }}
                            >
                                <Text
                                    fontSize="12px"
                                    cursor="pointer"
                                    onClick={onClose}
                                >
                                    Cancel
                                </Text>
                                <Spacer />
                                <Button
                                    w="67px"
                                    h="30px"
                                    bgColor="#0077C0"
                                    color="white"
                                    type="submit"
                                    disabled={false}
                                    fontSize="12px"
                                    _focus={{
                                        borderColor: 'none',
                                    }}
                                    _hover={{
                                        color: 'white',
                                        bgColor: '#0077C0',
                                    }}
                                    _disabled={{
                                        cursor: 'not-allowed',
                                        bgColor: '#DDDDDD !important',
                                        color: '#9A9A9A !important',
                                    }}
                                >
                                    Save
                                </Button>
                            </Flex>
                        </form>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

EmployeeEditModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    employee: PropTypes.object.isRequired,
    supervisor: PropTypes.object.isRequired,
    partnerList: PropTypes.array.isRequired,
    designationList: PropTypes.array.isRequired,
    setDataLoaderOpen: PropTypes.func.isRequired,
    setIsEmployeeUpdated: PropTypes.func.isRequired,
};

export default EmployeeEditModal;
