// COMMON
export const _FFFFFF = '#FFFFFF';
export const _F2F2F2 = '#F2F2F2';
export const _464646 = '#464646';
export const _828282 = '#828282';
export const _DADADA = '#DADADA';
export const _000000 = '#000000';
export const _DDDDDD = '#DDDDDD';
export const _F64646 = '#F64646';
export const _EDCF31 = '#EDCF31';
export const _219653 = '#219653';
export const _0077C0 = '#0077C0';
export const TABLE_HEADING_BG_COLOR = '#0077C0';
export const TABLE_HEADING_TEXT_COLOR = '#FFFFFF';
export const POPOVER_CONTENT_SIDE_COLOR = '#0077C0';
export const POPOVER_CONTENT_MIDDLE_BAR_COLOR = '#FFFFFF';
export const SICK_LEAVE_COLOR = '#FF0000';
export const ANNUAL_LEAVE_COLOR = '#0077C0';
export const CASUAL_LEAVE_COLOR = '#00C058';

// Leave report
export const YEARLY_FORECAST_GREEN_COLOR = '#219653';
export const YEARLY_FORECAST_YELLOW_COLOR = '#EDCF31';
export const YEARLY_FORECAST_RED_COLOR = '#F64646';
export const ODD_ROW_COLOR = '#F2F2F2';
export const EVEN_ROW_COLOR = '#FFFFFF';
export const ROW_HOVER_COLOR = '#0077C0';
export const LEAVE_FILTER_FIELDS_BG_COLOR_ONE = '#DADADA';
export const LEAVE_FILTER_FIELDS_BG_COLOR_TWO = '#F2F2F2';

// Sidebar
export const SIDEBAR_BG_COLOR = '#F2F2F2';
export const SIDEBAR_WHITE_ROUND_BOX_BG_COLOR = '#FFFFFF';
