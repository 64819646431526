import React from 'react';

import {
    Box,
    Flex,
    Image,
    Table,
    Tbody,
    Td,
    Text,
    Tr,
    Spacer,
} from '@chakra-ui/react';
import { PropTypes } from 'prop-types';

import calender from '../../../assets/icons/date.svg';
import CommonTableHeading from '../../../common/CommonTableHeading';
import CommonTooltip from '../../../common/CommonTooltip';
import { EMPLOYEE_LEAVE_DETAILS_CHARACTER_LIMIT } from '../../../constants/employeeManagementConstant';
import getShortFormText from '../../../helper/get-short-form-text';
import { getShortFormDate } from '../../../helper/utils';

function LeaveDetails({
    leaves,
    setAllLeaveModalOpen,
    hideLeavesDetailsModalTitle,
}) {
    const TABLE_HEADINGS = ['Leave Type', 'Total Leave', 'Dates of Leave'];

    const getTotalLeaveDays = () => {
        const totalLeavesCount = leaves.totalLeaveCount;
        return (
            <Text as="b">
                {totalLeavesCount > 0 ? totalLeavesCount : 0}
                {' days'}
            </Text>
        );
    };

    const getLeaveType = leaveType => {
        if (leaveType === 'SICK') {
            return 'Total sick leaves';
        } else if (leaveType === 'UNPAID') {
            return 'Total annual leaves';
        } else {
            return 'Total casual leaves';
        }
    };

    const getIndividualLeaveDateRange = leaveDateRange => {
        let allDateRange = '';
        for (let i = 0; i < leaveDateRange.length; i++) {
            if (allDateRange.length > 0) allDateRange += ', ';
            allDateRange +=
                getShortFormDate(leaveDateRange[i].from) +
                '-' +
                getShortFormDate(leaveDateRange[i].to);
        }
        return allDateRange;
    };

    const getTooltipUI = leave => {
        const getIndividualLeaveDateRanges = getIndividualLeaveDateRange(
            leave.details
        );
        const disabled =
            getIndividualLeaveDateRanges.length <=
            EMPLOYEE_LEAVE_DETAILS_CHARACTER_LIMIT;
        const content =
            leave.details.length > 0
                ? getShortFormText(
                      getIndividualLeaveDateRanges,
                      EMPLOYEE_LEAVE_DETAILS_CHARACTER_LIMIT
                  )
                : 'N/A';
        return (
            <CommonTooltip
                label={getIndividualLeaveDateRanges}
                disabled={disabled}
            >
                {content}
            </CommonTooltip>
        );
    };

    return (
        <React.Fragment>
            <Box>
                <Box overflowX="auto" fontSize="12px">
                    <Table
                        variant="simple"
                        w={['100%', '100%', '100%', '100%', '80%']}
                    >
                        <CommonTableHeading tableHeadings={TABLE_HEADINGS} />
                        <Tbody>
                            {leaves.details.map((leave, index) => (
                                <Tr
                                    key={index}
                                    bgColor={index & 1 ? '#EEEEEE' : '#FFFFFF'}
                                >
                                    <Td
                                        minW={['250px', '300px']}
                                        w={['250px', '300px']}
                                    >
                                        {getLeaveType(leave.type)}
                                    </Td>
                                    <Td
                                        minW={['150px', '300px']}
                                        w={['150px', '300px']}
                                    >
                                        {leave.count} {'days'}
                                    </Td>
                                    <Td
                                        minW={['300px', '300px']}
                                        w={['300px', '300px']}
                                        cursor="pointer"
                                    >
                                        {getTooltipUI(leave)}
                                    </Td>
                                </Tr>
                            ))}
                            <Tr>
                                <Td colSpan={3} border="none">
                                    <Flex>
                                        <Box ml={[2, 2, 2, 2, 0]}>
                                            <Text>
                                                {getTotalLeaveDays()}{' '}
                                                {' total leave'}
                                            </Text>
                                        </Box>
                                        <Spacer />
                                        <Box>
                                            {leaves.totalLeaveCount >= 0 &&
                                            hideLeavesDetailsModalTitle ? (
                                                <Flex
                                                    onClick={() =>
                                                        setAllLeaveModalOpen(
                                                            true
                                                        )
                                                    }
                                                    cursor="pointer"
                                                >
                                                    <Text pt={1.5}>
                                                        {'View all leave'}
                                                    </Text>
                                                    <Image
                                                        ml={2}
                                                        mt={2}
                                                        w="15px"
                                                        h="15px"
                                                        src={calender}
                                                    />
                                                </Flex>
                                            ) : null}
                                        </Box>
                                    </Flex>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Box>
            </Box>
        </React.Fragment>
    );
}

LeaveDetails.propTypes = {
    leaves: PropTypes.object.isRequired,
    setAllLeaveModalOpen: PropTypes.func.isRequired,
    hideLeavesDetailsModalTitle: PropTypes.bool.isRequired,
};

export default LeaveDetails;
