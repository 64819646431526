import React from 'react';

import { Box, Flex, Table, Tbody } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';

import CommonTableHeading from '../../common/CommonTableHeading';
import { getAllPartnerNameInString } from '../../helper/utils';
import LeavesReportTableRow from './LeavesReportTableRow';

const TABLE_HEADINGS = [
    'Employee Name',
    'Designation',
    'Partner',
    'Total Leave',
    'Unplanned Leave',
    'Monthly Average',
    'Yearly Forecast',
    'Leave Comparison',
];

function EmployeeLeaveResults({ results }) {
    return (
        <Flex mt="8px" direction="column" fontSize="12px">
            <Box overflow="hidden">
                <Box overflow="auto">
                    <Table variant="simple" justify="center" align="center">
                        <CommonTableHeading tableHeadings={TABLE_HEADINGS} />
                        <Tbody>
                            {results.content.map((result, index) => {
                                let partnersName = getAllPartnerNameInString(
                                    result.partner
                                );

                                return (
                                    <LeavesReportTableRow
                                        key={index}
                                        result={result}
                                        index={index}
                                        partnersName={partnersName}
                                    />
                                );
                            })}
                        </Tbody>
                    </Table>
                </Box>
            </Box>
        </Flex>
    );
}

EmployeeLeaveResults.propTypes = {
    results: PropTypes.object.isRequired,
};

export default EmployeeLeaveResults;
