import React from 'react';

import { Thead, Tr, Th } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { CALENDAR_TABLE_FIELD_PADDING } from '../constants/commonConstant';

const CalendarTableHeading = ({ tableHeadings }) => {
    return (
        <Thead>
            <Tr>
                {tableHeadings.map((heading, index) => {
                    return (
                        <Th
                            key={index}
                            p={CALENDAR_TABLE_FIELD_PADDING}
                            textAlign="center"
                            border="none"
                        >
                            {heading}
                        </Th>
                    );
                })}
            </Tr>
        </Thead>
    );
};

CalendarTableHeading.propTypes = {
    tableHeadings: PropTypes.array.isRequired,
};

export default CalendarTableHeading;
