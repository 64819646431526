import React, { useState } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import moment from 'moment';
import PropTypes from 'prop-types';

import DateRangePicker from '../../common/date-range-picker/DateRangePicker';
import { startingFromCurrentYear } from '../../helper/utils';
import LeaveReportDateRangePicker from './LeaveReportDateRangePicker';

const DATE_FILTER_MIN_DATE = '2018-01-01';
const CUSTOM_DATE_RANGE = 'custom_date_range';
const DATE_FORMAT = 'YYYY-MM-DD';
const MONTH_AND_YEAR_FORMAT = 'MMM YY';

const getPreviousMonth = date => {
    return moment(date).subtract(1, 'months').startOf('month');
};

const getLastThreeMonths = () => {
    const lastThreeMonths = [getPreviousMonth(new Date())];
    lastThreeMonths.push(
        getPreviousMonth(lastThreeMonths[lastThreeMonths.length - 1])
    );
    lastThreeMonths.push(
        getPreviousMonth(lastThreeMonths[lastThreeMonths.length - 1])
    );
    return lastThreeMonths;
};

const getDateFilterList = () => {
    const dateFilterList = getLastThreeMonths().map(month => {
        return {
            id: month.format(DATE_FORMAT),
            label: month.format(MONTH_AND_YEAR_FORMAT),
        };
    });
    dateFilterList.push({
        id: CUSTOM_DATE_RANGE,
        label: 'Custom date',
    });
    return dateFilterList;
};

const getDateRangeFromMonth = dateInString => {
    return [
        moment(dateInString).format(DATE_FORMAT),
        moment(dateInString).endOf('month').format(DATE_FORMAT),
    ];
};

const LeaveReportDateFilter = ({ onChange }) => {
    const [isShowCalendar, setIsShowCalendar] = useState(true);
    const [dateText, setDateText] = useState('');
    const [dateRange, setDateRange] = useState([
        startingFromCurrentYear(),
        moment(new Date()).format(DATE_FORMAT),
    ]);

    const onDateRangeChange = value => {
        setDateRange(value);
        onChange([
            moment(value[0]).format(DATE_FORMAT),
            moment(value[1]).format(DATE_FORMAT),
        ]);
    };

    const onSelectHandler = id => {
        if (id === CUSTOM_DATE_RANGE) {
            onChange([
                moment(dateRange[0]).format(DATE_FORMAT),
                moment(dateRange[1]).format(DATE_FORMAT),
            ]);
            setIsShowCalendar(true);
            setDateText('');
        } else {
            setIsShowCalendar(false);
            onChange(getDateRangeFromMonth(id));
            setDateText(moment(id).format(MONTH_AND_YEAR_FORMAT));
        }
    };

    return (
        <Box align="right" ml={2} fontSize="16px" fontWeight={400} my={0}>
            <Flex fontSize="16px">
                {isShowCalendar && (
                    <DateRangePicker
                        format="dd/MM/y"
                        value={dateRange}
                        clearIcon={null}
                        onChange={onDateRangeChange}
                        minDate={new Date(DATE_FILTER_MIN_DATE)}
                        maxDate={new Date(moment(new Date()).add(3, 'months'))}
                    />
                )}

                <Text
                    fontSize="16px"
                    pt="5px"
                    pl="4px"
                    pr="4px"
                    color="#828282"
                >
                    {dateText}
                </Text>

                <LeaveReportDateRangePicker
                    options={getDateFilterList()}
                    onSelectHandler={onSelectHandler}
                />

                <Text fontSize="16px" pt="5px" pl="4px" color="#828282">
                    Select date
                </Text>
            </Flex>
        </Box>
    );
};

LeaveReportDateFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default LeaveReportDateFilter;
