import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Box, useToast } from '@chakra-ui/react';

import CustomBreadcrumb from '../../common/CustomBreadcrumb';
import DataLoader from '../../common/DataLoader';
import PageTitle from '../../common/PageTitle';
import { EMPLOYEE_DETAILS_BREADCRUMB } from '../../constants/breadcrumbData';
import { makeColumnBarData } from '../../helper/attendance-bar-chart';
import { fillWholeCalendar } from '../../helper/fill-whole-calender';
import { currentDate, oneMonthLessThanCurrentMonth } from '../../helper/utils';
import EmployeeManagement from '../../service/employee-management';
import PartnerManagement from '../../service/partner-management';
import AllLeaveModal from '../components/EmployeeDetailsComponents/AllLeaveModal';
import AttendanceChart from '../components/EmployeeDetailsComponents/AttendanceChart';
import AverageWorkingHourModal from '../components/EmployeeDetailsComponents/AverageWorkingHourModal';
import EmployeeDateRangePicker from '../components/EmployeeDetailsComponents/EmployeeDateRangePicker';
import LeaveDetails from '../components/EmployeeDetailsComponents/LeaveDetails';
import PersonalInfo from '../components/EmployeeDetailsComponents/PersonalInfo';
import EmployeeEditModal from '../components/EmployeeEditModal';

export default function EmployeeDetailsNewDesign() {
    const PAGE_TITLE = 'Employee Details';

    const toast = useToast();
    const { id } = useParams();
    const [employee, setEmployee] = useState();
    const [isEmployeeEditModalOpen, setEmployeeEditModalOpen] = useState(false);
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);
    const [supervisor, setSupervisor] = useState('');
    const [partners, setPartners] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [isEmployeeUpdated, setIsEmployeeUpdated] = useState(false);
    const [leaveInfo, setLeaveInfo] = useState({ details: [] });
    const [leaveStartDate, setLeaveStartDate] = useState(
        oneMonthLessThanCurrentMonth
    );
    const [leaveEndDate, setLeaveEndDate] = useState(currentDate);
    const [attendanceStartDate, setAttendanceStartDate] = useState(
        oneMonthLessThanCurrentMonth
    );
    const [attendanceEndDate, setAttendanceEndDate] = useState(currentDate);
    const [attendanceResult, setAttendanceResult] = useState({
        attendances: [],
    });
    const [isAverageWorkingHourModalOpen, setAverageWorkingHourModalOpen] =
        useState(false);
    const [attendanceBarData, setAttendanceBarData] = useState(null);
    const [isAllLeaveModalOpen, setAllLeaveModalOpen] = useState(false);
    const [wholeCalender] = useState(fillWholeCalendar());
    const [hideLeavesDetailsModalTitle, setHideLeavesDetailsModalTitle] =
        useState(true);

    const leaveStartDateOnChange = useCallback(
        date => {
            setLeaveStartDate(date);
        },
        [leaveStartDate]
    );
    const leaveEndDateOnChange = useCallback(
        date => {
            setLeaveEndDate(date);
        },
        [leaveEndDate]
    );
    const attendanceStartDateOnChange = useCallback(
        date => {
            setAttendanceStartDate(date);
        },
        [attendanceStartDate]
    );
    const attendanceEndDateOnChange = useCallback(
        date => {
            setAttendanceEndDate(date);
        },
        [attendanceEndDate]
    );

    const fetchDesignation = async () => {
        try {
            const response = await EmployeeManagement.getAllDesignation();
            const designations = response.content.map(d => d.name);
            setDesignations(designations);
        } catch (error) {
            toast({
                title: 'Designation fetching failed',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const fetchPartners = async () => {
        try {
            const response = await PartnerManagement.getAllPartner();
            setPartners(response.partners);
            setDataLoaderOpen(false);
        } catch (error) {
            toast({
                title: 'Partner fetching failed',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        fetchDesignation();
        fetchPartners();
    }, []);

    // Fetching employee details and supervisors via API call
    useEffect(() => {
        setIsEmployeeUpdated(false);
        const fetchData = async () => {
            const response1 = await EmployeeManagement.getEmployeeDetails(id);
            const response2 = await EmployeeManagement.getEmployeeDetails(
                response1.supervisorId
            );
            setEmployee(response1);
            setSupervisor(response2);
        };

        fetchData().catch(error => {
            toast({
                title: 'Data Fetching Fail',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        });
    }, [isEmployeeUpdated, isDataLoaderOpen]);

    const getLeaveDetails = async () => {
        try {
            if (isValidDateRange(leaveStartDate, leaveEndDate)) {
                toast({
                    title: 'Invalid date range',
                    description: 'Choose a valid date range',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
                const resetObject = {
                    totalLeaveCount: 0,
                    details: [
                        {
                            type: 'CASUAL',
                            count: 0,
                            details: [],
                        },
                        {
                            type: 'SICK',
                            count: 0,
                            details: [],
                        },
                        {
                            type: 'ANNUAL',
                            count: 0,
                            details: [],
                        },
                    ],
                };
                setLeaveInfo(resetObject);
                return;
            }
            if (leaveStartDate && leaveEndDate) {
                const response = await EmployeeManagement.getLeaveDetails(
                    id,
                    leaveStartDate,
                    leaveEndDate
                );
                setLeaveInfo(response);
            }
        } catch (error) {
            setDataLoaderOpen(false);
            toast({
                title: 'Data Fetching Fail',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        getLeaveDetails();
    }, []);

    const getAttendanceDetails = async () => {
        try {
            if (isValidDateRange(attendanceStartDate, attendanceEndDate)) {
                toast({
                    title: 'Invalid date range',
                    description: 'Choose a valid date range',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
                const resetObject = {
                    averageWorkingTimeInMinutes: 0,
                    dimension: 'Day',
                    attendances: [],
                };
                setAttendanceBarData(makeColumnBarData(resetObject));
                setAttendanceResult(resetObject);
                return;
            }
            if (attendanceStartDate && attendanceEndDate) {
                const response = await EmployeeManagement.getAttendanceDetails(
                    id,
                    attendanceStartDate,
                    attendanceEndDate
                );
                setAttendanceBarData(makeColumnBarData(response));
                setAttendanceResult(response);
            }
        } catch (error) {
            toast({
                title: 'Data Fetching Fail',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        getAttendanceDetails();
    }, []);

    const isValidDateRange = (from, to) => {
        const fromDate = new Date(from);
        const toDate = new Date(to);
        const oneDayInMiliSecond = 1000 * 60 * 60 * 24;
        const totalDayInYear = 360;
        const timeDifference = toDate.getTime() - fromDate.getTime();
        const totalDays = timeDifference / oneDayInMiliSecond;

        if (timeDifference < 0) return false;
        if (totalDays > totalDayInYear) setHideLeavesDetailsModalTitle(false);
    };

    if (isDataLoaderOpen || isEmployeeUpdated || !employee)
        return <DataLoader isOpen={isDataLoaderOpen} />;

    if (isEmployeeEditModalOpen) {
        return (
            <EmployeeEditModal
                isOpen={isEmployeeEditModalOpen}
                onClose={() => setEmployeeEditModalOpen(false)}
                employee={employee}
                supervisor={supervisor}
                partnerList={partners}
                designationList={designations}
                setDataLoaderOpen={setDataLoaderOpen}
                setIsEmployeeUpdated={setIsEmployeeUpdated}
            />
        );
    }

    return (
        <Box>
            {isAverageWorkingHourModalOpen ? (
                <AverageWorkingHourModal
                    isOpen={isAverageWorkingHourModalOpen}
                    onClose={() => setAverageWorkingHourModalOpen(false)}
                    attendanceBarData={attendanceBarData}
                />
            ) : null}
            {isAllLeaveModalOpen ? (
                <AllLeaveModal
                    isOpen={isAllLeaveModalOpen}
                    onClose={() => setAllLeaveModalOpen(false)}
                    wholeCalender={wholeCalender}
                    leaveInfo={leaveInfo}
                />
            ) : null}
            <Box bgColor="#FFFFFF">
                <CustomBreadcrumb
                    allBreadcrumbData={EMPLOYEE_DETAILS_BREADCRUMB}
                />
            </Box>

            <Box direction="row" my="16px">
                <PageTitle title={PAGE_TITLE} />
            </Box>

            <Box ml={[0, 0, 0, 0, '16%']}>
                <PersonalInfo
                    results={employee}
                    partnerList={partners}
                    designationList={designations}
                    supervisor={supervisor}
                    isEmployeeEditModalOpen={isEmployeeEditModalOpen}
                    setEmployeeEditModalOpen={setEmployeeEditModalOpen}
                />
                <Box my={6}>
                    <EmployeeDateRangePicker
                        heading={'Employee Leave Details'}
                        startDefaultDate={leaveStartDate}
                        endDefaultDate={leaveEndDate}
                        startDateOnChange={leaveStartDateOnChange}
                        endDateOnChange={leaveEndDateOnChange}
                        getDetails={getLeaveDetails}
                    />

                    <LeaveDetails
                        leaves={leaveInfo}
                        setAllLeaveModalOpen={setAllLeaveModalOpen}
                        hideLeavesDetailsModalTitle={
                            hideLeavesDetailsModalTitle
                        }
                    />
                </Box>
                <Box my={6}>
                    <EmployeeDateRangePicker
                        heading={'Attendance Chart'}
                        startDefaultDate={attendanceStartDate}
                        endDefaultDate={attendanceEndDate}
                        startDateOnChange={attendanceStartDateOnChange}
                        endDateOnChange={attendanceEndDateOnChange}
                        getDetails={getAttendanceDetails}
                    />

                    <AttendanceChart
                        attendances={attendanceResult}
                        setAverageWorkingHourModalOpen={
                            setAverageWorkingHourModalOpen
                        }
                    />
                </Box>
            </Box>
        </Box>
    );
}
