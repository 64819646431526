import React from 'react';

import { Box, Flex, Image, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import downloadIcon from '../assets/icons/download.svg';

const DownloadListTab = ({ exportCsv }) => {
    return (
        <Box
            align="right"
            my="16px"
            mr={{
                lg: '1px',
                md: '1px',
                sm: '4px',
                base: '10px',
            }}
            fontSize="14px"
            fontWeight="400"
            cursor="pointer"
            onClick={exportCsv}
        >
            <Flex direction="row" pt="5px">
                <Image
                    mx={2}
                    w="22px"
                    h="25px"
                    src={downloadIcon}
                    alt="download icon"
                />
                <Text color="#828282" pt="2px">
                    Download List
                </Text>
            </Flex>
        </Box>
    );
};

DownloadListTab.propTypes = {
    exportCsv: PropTypes.func.isRequired,
};

export default DownloadListTab;
