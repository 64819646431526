import { HStack, Image, Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import arrow from '../assets/icons/arrow_drop_down.svg';

export const NavLink = ({ icon, isActive, label, lShow, color, ...rest }) => {
    return (
        <Link
            display="block"
            py={3}
            px={{ lg: 5, base: 3 }}
            pl={
                label === 'Leave Report' || label === 'Attendance Report'
                    ? { lg: 10, base: 6 }
                    : { lg: 5, base: 3 }
            }
            pr={{ lg: 5, base: 3 }}
            borderRadius="3px"
            fontWeight={500}
            lineHeight="1.5rem"
            fontSize={{ lg: '18px', base: '16px' }}
            letterSpacing={-0.1}
            aria-current={isActive && !color ? 'page' : undefined}
            color={color}
            _hover={
                label.length !== 0
                    ? {
                          bg: '#D9D9D9 !important',
                      }
                    : ''
            }
            _activeLink={{
                bg: '#D9D9D9 !important',
                color: '#0077C0',
            }}
            {...rest}
        >
            <HStack spacing={3}>
                {lShow.length > 0 && isActive ? <Text>{lShow}</Text> : icon}
                {label !== '' && <Text as="span">{label}</Text>}{' '}
                {label === 'Reports' && <Image src={arrow} alt="down arrow" />}{' '}
            </HStack>
        </Link>
    );
};

NavLink.propTypes = {
    icon: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    label: PropTypes.string.isRequired,
    lShow: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
};

export default NavLink;
