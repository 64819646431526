import React, { useState } from 'react';

import {
    Avatar,
    Box,
    Flex,
    Image,
    Input,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Select,
    Table,
    Tbody,
    Td,
    Text,
    Tr,
    WrapItem,
} from '@chakra-ui/react';
import { PropTypes } from 'prop-types';

import searchIcon from '../../assets/icons/searchIcon.svg';
import * as color from '../../constants/colorCode';
import {
    EMPLOYEE_NAME_KEY,
    INPUT_KEY,
    PARTNER_KEY,
    ROLE_DEPARTMENT_KEY,
    SELECT_KEY,
} from '../../constants/commonConstant';

export const inputOrSelectFieldCss = {
    w: {
        lg: '170px',
        base: '90%',
    },
    h: '32px',
    borderLeftRadius: '0px',
    border: '0px',
    color: color._828282,
    _placeholder: {
        color: color._828282,
    },
    _focusVisible: {
        borderColor: color._DDDDDD,
    },
};

const FilterOptions = ({
    nameList,
    employeesByName,
    setEmployeesByName,
    setNameListQueryParameter,
    employeeSearchByName,
    partners,
    selectedPartner,
    setSelectedPartner,
    selectedDesignationsLength,
    designationsList,
    setDesignationListQueryParameter,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const open = () => setIsOpen(!isOpen);
    const close = () => setIsOpen(false);
    const [selectedName, setSelectedName] = useState('');

    const API_URL = process.env.REACT_APP_SERVER_URL;

    let nameFieldBgColor =
        nameList.length > 0
            ? color.LEAVE_FILTER_FIELDS_BG_COLOR_ONE
            : color.LEAVE_FILTER_FIELDS_BG_COLOR_TWO;
    let designationFieldBgColor =
        selectedDesignationsLength > 0
            ? color.LEAVE_FILTER_FIELDS_BG_COLOR_ONE
            : color.LEAVE_FILTER_FIELDS_BG_COLOR_TWO;
    let partnerFieldBgColor = selectedPartner
        ? color.LEAVE_FILTER_FIELDS_BG_COLOR_ONE
        : color.LEAVE_FILTER_FIELDS_BG_COLOR_TWO;

    const designationOptions = designationsList.map(designation => (
        <option value={designation} key={designation}>
            {designation}
        </option>
    ));

    const partnerOptions = partners.map(({ id, name }) => (
        <option value={name} key={id}>
            {name}
        </option>
    ));

    const nameSelectHandler = e => {
        setSelectedName(e.target.value);
        employeeSearchByName(e.target.value);
        e.target.value.length === 0 ? setEmployeesByName([]) : null;
    };

    const designationsSelectedHandler = e => {
        setDesignationListQueryParameter(e.target.value);
    };

    const partnerSelectHandler = e => {
        setSelectedPartner(e.target.value);
    };

    const getIndividualFilteringFields = (
        fieldType,
        placeholder,
        fieldBgColor,
        dropdownOptions,
        onChangeHandler,
        value = ''
    ) => {
        return (
            <Flex direction="row" cursor="pointer" borderRadius="4px">
                <Image
                    pl="8px"
                    bg={fieldBgColor}
                    src={searchIcon}
                    borderLeftRadius="6px"
                />
                {fieldType === SELECT_KEY ? (
                    <Select
                        {...inputOrSelectFieldCss}
                        bg={fieldBgColor}
                        placeholder={placeholder}
                        value={value}
                        onChange={e => onChangeHandler(e)}
                    >
                        {dropdownOptions}
                    </Select>
                ) : (
                    <Input
                        {...inputOrSelectFieldCss}
                        bg={fieldBgColor}
                        placeholder={placeholder}
                        value={value}
                        onChange={e => nameSelectHandler(e)}
                    />
                )}
            </Flex>
        );
    };

    const getEmployeesNameContent = employeesByName => {
        return (
            <Table
                variant="simple"
                justify="center"
                align="center"
                fontSize="14px"
            >
                <Tbody>
                    {employeesByName.map((result, index) => (
                        <Tr key={index}>
                            <Td
                                p={2}
                                cursor="pointer"
                                onClick={() => {
                                    setNameListQueryParameter(
                                        result.employeeId
                                    );
                                    close();
                                    setSelectedName('');
                                    setEmployeesByName([]);
                                }}
                            >
                                <Flex direction="row">
                                    <WrapItem>
                                        <Avatar
                                            size="sm"
                                            src={`${API_URL}${result.profilePictureUrl}`}
                                            name={result.name}
                                        />{' '}
                                    </WrapItem>
                                    <Text pl="6px" pt="6px" align="center">
                                        {result.name}
                                    </Text>
                                </Flex>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        );
    };

    return (
        <Flex direction={{ lg: 'row', base: 'column' }}>
            <Popover
                returnFocusOnClose={false}
                isOpen={employeesByName.length > 0 && selectedName.length > 0}
                onClose={close}
                closeOnBlur={true}
                placement="bottom"
            >
                <PopoverTrigger>
                    <Box mt="10px" onClick={open}>
                        {getIndividualFilteringFields(
                            INPUT_KEY,
                            EMPLOYEE_NAME_KEY,
                            nameFieldBgColor,
                            null,
                            nameSelectHandler,
                            selectedName
                        )}
                    </Box>
                </PopoverTrigger>

                <PopoverContent
                    w="215px"
                    h="auto"
                    _focusVisible={{
                        borderColor: '#FFFFFF !important',
                    }}
                >
                    <PopoverBody p={0}>
                        {getEmployeesNameContent(employeesByName)}
                    </PopoverBody>
                </PopoverContent>
            </Popover>

            <Box mt="10px" ml={{ lg: '10px', base: '0' }}>
                {getIndividualFilteringFields(
                    SELECT_KEY,
                    ROLE_DEPARTMENT_KEY,
                    designationFieldBgColor,
                    designationOptions,
                    designationsSelectedHandler
                )}
            </Box>
            <Box mt="10px" ml={{ lg: '10px', base: '0' }}>
                {getIndividualFilteringFields(
                    SELECT_KEY,
                    PARTNER_KEY,
                    partnerFieldBgColor,
                    partnerOptions,
                    partnerSelectHandler
                )}
            </Box>
        </Flex>
    );
};

FilterOptions.propTypes = {
    nameList: PropTypes.array.isRequired,
    employeesByName: PropTypes.array.isRequired,
    setEmployeesByName: PropTypes.func.isRequired,
    setNameListQueryParameter: PropTypes.func.isRequired,
    employeeSearchByName: PropTypes.func.isRequired,
    partners: PropTypes.array.isRequired,
    selectedPartner: PropTypes.string,
    setSelectedPartner: PropTypes.func.isRequired,
    selectedDesignationsLength: PropTypes.number.isRequired,
    designationsList: PropTypes.array.isRequired,
    setDesignationListQueryParameter: PropTypes.func.isRequired,
};

export default FilterOptions;
