export const makePieGraphForLeave = data => {
    const getSeries = () => {
        return data.map(d => d.leaveCount);
    };

    const getLabels = () => {
        return data.map(d => d.leaveType);
    };

    const getColors = () => {
        const CASUAL_LEAVE_COLOR = '#FFD797';
        const SICK_LEAVE_COLOR = '#A696F8';
        const UNPAID_LEAVE_COLOR = '#D39DE5';

        return [CASUAL_LEAVE_COLOR, SICK_LEAVE_COLOR, UNPAID_LEAVE_COLOR];
    };

    return {
        series: getSeries(),
        options: {
            colors: getColors(),
            labels: getLabels(),
            chart: {
                type: 'donut',
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return Math.round(val) + '%';
                },
            },
            plotOptions: {
                pie: {
                    customScale: 0.8,
                    donut: {
                        size: '60%',
                    },
                },
            },
            responsive: [
                {
                    show: true,
                    breakpoint: 200,
                },
            ],
        },
    };
};
