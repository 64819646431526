import { ACCESS_TOKEN_KEY } from '../constants/commonConstant';

const EmailDecoder = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (accessToken && accessToken.split('.').length > 1) {
        return JSON.parse(atob(accessToken.split('.')[1])).sub;
    } else return false;
};

export default EmailDecoder;
