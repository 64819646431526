import React from 'react';

import {
    Box,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Tooltip,
    Tr,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import CommonTableHeading from '../../common/CommonTableHeading';
import { TeamDescriptionCharacterLimit } from '../../constants/teamManagementConstant';
import getShortFormText from '../../helper/get-short-form-text';

function TeamTableResults({ results, navigateToTeamDetails }) {
    const TABLE_HEADINGS = ['Name of the Team', 'Description'];
    return (
        <Flex mt="8px" direction="column" fontSize="12px">
            <Box overflow="auto">
                <Table variant="simple" justify="center" align="center">
                    <CommonTableHeading tableHeadings={TABLE_HEADINGS} />

                    <Tbody>
                        {results.content.map((result, index) => (
                            <Tr
                                key={result.id}
                                bgColor={index & 1 ? '#F2F2F2' : '#FFFFFF'}
                                onClick={() => navigateToTeamDetails(result)}
                                cursor="pointer"
                                _hover={{
                                    bg: '#0077C0 !important',
                                    color: '#FFFFFF',
                                }}
                            >
                                <Td
                                    p={3}
                                    minW={['260px', '300px']}
                                    w={['260px', '300px']}
                                >
                                    {result.name}
                                </Td>
                                <Td
                                    p={3}
                                    minW={['300px', 'auto']}
                                    w={['300px', 'auto']}
                                >
                                    {result.description.trim().length >
                                    TeamDescriptionCharacterLimit ? (
                                        <Tooltip
                                            hasArrow
                                            label={result.description}
                                            cursor="pointer"
                                            bg="#FFFFFF"
                                            color="#4F4F4F"
                                            borderRadius="5px"
                                        >
                                            {getShortFormText(
                                                result.description,
                                                TeamDescriptionCharacterLimit
                                            )}
                                        </Tooltip>
                                    ) : (
                                        <Text>{result.description}</Text>
                                    )}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </Flex>
    );
}

TeamTableResults.propTypes = {
    results: PropTypes.object.isRequired,
    navigateToTeamDetails: PropTypes.func.isRequired,
};

export default TeamTableResults;
