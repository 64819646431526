import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Flex, Table, Tbody, Td, Tr, Text } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';

import CommonTableHeading from '../../common/CommonTableHeading';
import CommonTooltip from '../../common/CommonTooltip';
import { TABLE_FIELD_PADDING } from '../../constants/commonConstant';
import {
    EMPLOYEE_NAME_CHARACTER_LIMIT,
    EMPLOYEE_DESIGNATION_CHARACTER_LIMIT,
} from '../../constants/employeeManagementConstant';
import { buildEmployeePath } from '../../constants/pathNameConstants';
import getShortFormText from '../../helper/get-short-form-text';
import { getHumanReadableDate } from '../../helper/utils';

const NAME_WIDTH = ['200px', '210px', '220px'];
const EMAIL_WIDTH = '200px';
const EMPLOYEE_ID_WIDTH = ['130px', '140px'];
const JOINING_DATE_WIDTH = '140px';
const DESIGNATION_WIDTH = ['240px', '250px'];
const STATUS_WIDTH = '110px';

function EmployeeTableResults({ results }) {
    const TABLE_HEADINGS = [
        'Name of the Employee',
        'Email Id',
        'Employee Id',
        'Joining Date',
        'Designation',
        'Status',
    ];
    const navigate = useNavigate();

    async function navigateToEmployeeDetails(employeeId) {
        navigate(buildEmployeePath(employeeId));
    }

    return (
        <Flex
            mt="8px"
            direction="column"
            fontSize={{ lg: '12px', base: '12px' }}
        >
            <Box overflow="hidden">
                <Box overflow="auto">
                    <Table variant="simple" justify="center" align="center">
                        <CommonTableHeading tableHeadings={TABLE_HEADINGS} />
                        <Tbody>
                            {results.content.map((result, index) => (
                                <Tr
                                    key={result.employeeId}
                                    bgColor={index & 1 ? '#F2F2F2' : '#FFFFFF'}
                                    onClick={() =>
                                        navigateToEmployeeDetails(
                                            result.employeeId
                                        )
                                    }
                                    cursor="pointer"
                                    _hover={{
                                        bg: '#0077C0 !important',
                                        color: '#FFFFFF',
                                    }}
                                >
                                    <Td
                                        p={TABLE_FIELD_PADDING}
                                        minW={NAME_WIDTH}
                                        w={NAME_WIDTH}
                                    >
                                        {result.name === null ||
                                        result.name.length === 0 ? (
                                            <Text>{''}</Text>
                                        ) : (
                                            <CommonTooltip
                                                label={result.name}
                                                disabled={
                                                    result.name.length <=
                                                    EMPLOYEE_NAME_CHARACTER_LIMIT
                                                }
                                            >
                                                {getShortFormText(
                                                    result.name,
                                                    EMPLOYEE_NAME_CHARACTER_LIMIT
                                                )}
                                            </CommonTooltip>
                                        )}
                                    </Td>
                                    <Td
                                        p={TABLE_FIELD_PADDING}
                                        minW={EMAIL_WIDTH}
                                        w={EMAIL_WIDTH}
                                    >
                                        {result.email}
                                    </Td>
                                    <Td
                                        p={TABLE_FIELD_PADDING}
                                        minW={EMPLOYEE_ID_WIDTH}
                                        w={EMPLOYEE_ID_WIDTH}
                                    >
                                        {result.employeeId}
                                    </Td>
                                    <Td
                                        p={TABLE_FIELD_PADDING}
                                        minW={JOINING_DATE_WIDTH}
                                        w={JOINING_DATE_WIDTH}
                                    >
                                        {getHumanReadableDate(
                                            result.joiningDate
                                        )}
                                    </Td>
                                    <Td
                                        p={TABLE_FIELD_PADDING}
                                        minW={DESIGNATION_WIDTH}
                                        w={DESIGNATION_WIDTH}
                                    >
                                        <CommonTooltip
                                            label={result.designation}
                                            disabled={
                                                result.designation.trim()
                                                    .length <=
                                                EMPLOYEE_DESIGNATION_CHARACTER_LIMIT
                                            }
                                        >
                                            {getShortFormText(
                                                result.designation,
                                                EMPLOYEE_DESIGNATION_CHARACTER_LIMIT
                                            )}
                                        </CommonTooltip>
                                    </Td>
                                    <Td
                                        p={TABLE_FIELD_PADDING}
                                        minW={STATUS_WIDTH}
                                        w={STATUS_WIDTH}
                                    >
                                        {result.status}
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </Box>
        </Flex>
    );
}

EmployeeTableResults.propTypes = {
    results: PropTypes.object.isRequired,
};

export default EmployeeTableResults;
