import { Box, Table, Tbody, Td, Tr, Text } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';

import CalendarTableHeading from '../../../common/CalendarTableHeading';
import {
    CALENDAR_TABLE_FIELD_PADDING,
    months,
} from '../../../constants/commonConstant';

const Calendar = ({ results, allLeaveTypeAndLeaveDate, month }) => {
    const TABLE_HEADINGS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

    const foundDate = dateNo => {
        if (dateNo === 0) return null;
        let date = dateNo;
        let monthNo = months.find(m => m.value === month).key - 1;
        let year = new Date().getFullYear();
        let returnValue = allLeaveTypeAndLeaveDate.find(item => {
            return (
                Number(date) == item.date &&
                Number(monthNo) == item.month &&
                Number(year) == item.year
            );
        });

        return returnValue;
    };

    const getTableDataForCalendarDateColor = (
        result,
        currentDay,
        prevDay,
        nextDay
    ) => {
        return (
            <Td
                p={CALENDAR_TABLE_FIELD_PADDING}
                textAlign="center"
                border="none"
                color={currentDay ? '#FFFFFF' : ''}
                bg={currentDay ? currentDay.color : '#FFFFFF'}
                borderLeftRadius={prevDay ? 'null' : '50px'}
                borderRightRadius={nextDay ? 'null' : '50px'}
            >
                {result === 0 ? '' : result}
            </Td>
        );
    };

    return (
        <Box px="20px">
            <Text pl={[3, 7, 3, 3]} fontWeight={700} mb="20px">
                {month}
            </Text>
            <Table variant="simple">
                <CalendarTableHeading tableHeadings={TABLE_HEADINGS} />
                <Tbody>
                    {results.map((result, index) => {
                        const sunday = foundDate(result[0]);
                        const monday = foundDate(result[1]);
                        const tuesday = foundDate(result[2]);
                        const wednesday = foundDate(result[3]);
                        const thursday = foundDate(result[4]);

                        return (
                            <Tr key={index}>
                                {getTableDataForCalendarDateColor(
                                    result[0],
                                    sunday,
                                    null,
                                    monday
                                )}
                                {getTableDataForCalendarDateColor(
                                    result[1],
                                    monday,
                                    sunday,
                                    tuesday
                                )}
                                {getTableDataForCalendarDateColor(
                                    result[2],
                                    tuesday,
                                    monday,
                                    wednesday
                                )}
                                {getTableDataForCalendarDateColor(
                                    result[3],
                                    wednesday,
                                    tuesday,
                                    thursday
                                )}
                                {getTableDataForCalendarDateColor(
                                    result[4],
                                    thursday,
                                    wednesday,
                                    null
                                )}
                                {getTableDataForCalendarDateColor(
                                    result[5],
                                    false,
                                    false,
                                    false
                                )}
                                {getTableDataForCalendarDateColor(
                                    result[6],
                                    null,
                                    null,
                                    null
                                )}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </Box>
    );
};

Calendar.propTypes = {
    results: PropTypes.array.isRequired,
    allLeaveTypeAndLeaveDate: PropTypes.array.isRequired,
    month: PropTypes.string.isRequired,
};

export default Calendar;
