import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Flex, Image, Input, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import addEmployeeIcon from '../../assets/icons/addIcon.svg';
import searchIcon from '../../assets/icons/searchIcon.svg';

const EmployeesFeatures = ({
    searchName,
    setSearchName,
    employeeSearchByName,
    setPageNumber,
}) => {
    const navigate = useNavigate();

    return (
        <Flex direction={['column', 'row']} my="16px">
            <Box align="right" ml={2} fontSize="16px" fontWeight="400" my={0}>
                <Flex direction="row" cursor="pointer">
                    <Image mr={2} src={searchIcon} />
                    <Input
                        p={1}
                        w={
                            searchName.length > 0
                                ? {
                                      lg: '200px',
                                      md: '150px',
                                      sm: '130px',
                                      base: '130px',
                                  }
                                : '70px'
                        }
                        h="25px"
                        border="0px"
                        placeholder="Search"
                        _placeholder={{
                            color: '#828282 !important',
                        }}
                        _focusVisible={{
                            borderColor: '#DDDDDD !important',
                        }}
                        onChange={e => {
                            setSearchName(e.target.value.trim());
                            employeeSearchByName(e.target.value.trim());
                            if (e.target.value.trim().length >= 1) {
                                setPageNumber(0);
                            }
                        }}
                    />
                </Flex>
            </Box>
            <Box
                align="right"
                //mr={6}
                fontSize="16px"
                fontWeight="400"
            >
                <Flex
                    direction="row"
                    cursor="pointer"
                    onClick={() => navigate('/employee/add')}
                >
                    <Image mx={2} src={addEmployeeIcon} />
                    <Text color="#828282"> New Employee </Text>
                </Flex>
            </Box>
            {/* TODO: Will add later */}
            {/* <Box
                            align="right"
                            mr={6}
                            fontSize="16px"
                            fontWeight="400"
                        >
                            <Flex direction="row" cursor="pointer">
                                <Image mx={2} src={filterIcon} />
                                <Text color="#828282"> Filter </Text>
                            </Flex>
                        </Box> */}
        </Flex>
    );
};

EmployeesFeatures.propTypes = {
    searchName: PropTypes.string.isRequired,
    setSearchName: PropTypes.func.isRequired,
    employeeSearchByName: PropTypes.func.isRequired,
    setPageNumber: PropTypes.func.isRequired,
};

export default EmployeesFeatures;
