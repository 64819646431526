import React, { useEffect } from 'react';

import {
    Pagination,
    PaginationContainer,
    PaginationNext,
    PaginationPage,
    PaginationPageGroup,
    PaginationPrevious,
    PaginationSeparator,
    usePagination,
} from '@ajna/pagination';
import { Box, Flex, Spacer, Text, useMediaQuery } from '@chakra-ui/react';
import PropTypes from 'prop-types';

function CommonPagination({ page, size, totalPages, setPageNo }) {
    const [isLargerThan700] = useMediaQuery('(min-width: 700px)');

    const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
        pagesCount: totalPages,
        limits: {
            outer: 2,
            inner: 1,
        },
        initialState: { currentPage: 1 },
    });

    const handlePageChange = nextPage => {
        setCurrentPage(nextPage);
        setPageNo(nextPage - 1);
    };

    useEffect(() => {
        setCurrentPage(Number(page) + 1);
    }, [page]);

    return (
        <Flex
            bgColor={size & 1 ? '#F2F2F2' : '#F2F2F2'}
            pt="16px"
            pb="8px"
            borderBottomRadius="6px"
        >
            <Spacer />
            <Box>
                <Pagination
                    pagesCount={pagesCount}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                >
                    <PaginationContainer
                        align="center"
                        justify="space-between"
                        w="full"
                    >
                        <PaginationPrevious
                            mx={{ lg: 4, base: 2 }}
                            _hover={{ bg: 'none' }}
                            bg="none"
                            color="#4F4F4F"
                            fontSize="14px"
                        >
                            <Text>Previous</Text>
                        </PaginationPrevious>
                        {isLargerThan700 && (
                            <PaginationPageGroup
                                isInline
                                align="center"
                                separator={
                                    <PaginationSeparator
                                        fontSize="sm"
                                        w={5}
                                        mx={0}
                                        jumpSize={11}
                                    />
                                }
                            >
                                {pages.map(page => (
                                    <PaginationPage
                                        px={3}
                                        minW={10}
                                        bg={size & 1 ? '#F2F2F2' : ' '}
                                        color="#4F4F4F"
                                        key={`pagination_page_${page}`}
                                        page={page}
                                        fontSize="14px"
                                        _hover={{
                                            bg: 'blackAlpha.300',
                                        }}
                                        _current={{
                                            bg: '#0077C0',
                                            fontSize: 'sm',
                                            textColor: '#FFFFFF',
                                            w: 0,
                                        }}
                                    />
                                ))}
                            </PaginationPageGroup>
                        )}
                        <PaginationNext
                            _hover={{ bg: 'none' }}
                            mx={{ lg: 4, base: 2 }}
                            color="#4F4F4F"
                            fontSize="14px"
                            bg="none"
                        >
                            <Text>Next</Text>
                        </PaginationNext>
                    </PaginationContainer>
                </Pagination>
            </Box>
        </Flex>
    );
}

CommonPagination.propTypes = {
    page: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    setPageNo: PropTypes.func.isRequired,
};

export default CommonPagination;
