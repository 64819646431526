import {
    AVERAGE_LEAVE_BAR_WIDTH,
    MAX_HEIGHT_OF_COMPARISON_BAR,
    MIN_HEIGHT_OF_COMPARISON_BAR,
} from '../../constants/reportManagementConstants';

export const plannedAndUplannedBarWidth = (
    planLeave,
    unplanLeave,
    totalLeaveTaken
) => {
    let planLeaveBarWidth = (planLeave * 100) / totalLeaveTaken;
    planLeaveBarWidth =
        planLeaveBarWidth > MAX_HEIGHT_OF_COMPARISON_BAR
            ? MAX_HEIGHT_OF_COMPARISON_BAR
            : planLeaveBarWidth;
    let unplanLeaveBarWidth = (unplanLeave * 100) / totalLeaveTaken;
    unplanLeaveBarWidth =
        unplanLeaveBarWidth < MIN_HEIGHT_OF_COMPARISON_BAR
            ? MIN_HEIGHT_OF_COMPARISON_BAR
            : unplanLeaveBarWidth;
    let averageLeaveBarWidth = AVERAGE_LEAVE_BAR_WIDTH;

    return { planLeaveBarWidth, unplanLeaveBarWidth, averageLeaveBarWidth };
};
