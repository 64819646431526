import React from 'react';

import { Box, Flex, Image, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import filterIcon from '../../assets/icons/filterIcon.svg';
import * as Type from '../../constants/actionTypeConstant';
import * as color from '../../constants/colorCode';
import { useInformation } from '../../contexts/informationContext';

const FilterPicker = ({ isOpenFilterOption }) => {
    const {
        state: { isFilterOptionOpenForLeave },
        dispatch,
    } = useInformation();

    const removeAllFilterFieldExpectDate = value => {
        dispatch({
            type: Type.IS_FILTER_OPTION_OPEN_FOR_LEAVE,
            payload: value,
        });
    };

    return (
        <Box
            align="right"
            mr={{ lg: '1px', md: '1px', sm: '4px', base: '10px' }}
            fontSize="14px"
            fontWeight={400}
            onClick={() =>
                removeAllFilterFieldExpectDate(!isFilterOptionOpenForLeave)
            }
        >
            <Flex direction="row" cursor="pointer" pt="5px">
                <Image mx={2} w="25px" h="25px" src={filterIcon} />
                <Text
                    color={isOpenFilterOption ? color._464646 : color._828282}
                    fontWeight={isOpenFilterOption ? 600 : 400}
                    pt="2px"
                >
                    {' '}
                    Filter{' '}
                </Text>
            </Flex>
        </Box>
    );
};

FilterPicker.propTypes = {
    isOpenFilterOption: PropTypes.bool.isRequired,
};

export default FilterPicker;
