import React from 'react';
import { useState } from 'react';

import {
    Box,
    Th,
    Thead,
    Table,
    Tbody,
    Td,
    Tr,
    Flex,
    Button,
    Select,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { months, years } from '../constants/commonConstant';

const SingleCalendar = ({ results, selectedDate, setSelectDate }) => {
    const tableBoxPadding = '4px';
    const [day, setDay] = useState(new Date(selectedDate).getDate());
    const [month, setMonth] = useState(new Date(selectedDate).getMonth() + 1);
    const [year, setYear] = useState(new Date(selectedDate).getFullYear());

    const onClickDateChangeHandler = d => {
        if (d == 0) return;
        setDay(d);
        let makeStringDay = String(d).padStart(2, '0');
        let makeStringMonth = String(month).padStart(2, '0');
        setSelectDate(`${year}-${makeStringMonth}-${makeStringDay}`);
    };

    return (
        <Box px="20px" pb="5px">
            <Box pb="10px" outline="none">
                <Flex direction="row">
                    <Select
                        bgColor="white"
                        value={month}
                        readOnly
                        w="120px"
                        h="30px"
                        pr={0}
                        name="month"
                        border="0px"
                        fontSize="14px"
                        _focusVisible={{
                            outline: 'none',
                        }}
                        onChange={e => {
                            setMonth(e.target.value);
                            setSelectDate(
                                `${year}-${String(e.target.value).padStart(
                                    2,
                                    '0'
                                )}-${day}`
                            );
                        }}
                    >
                        {months.map(({ value, key }) => (
                            <option value={key} key={key}>
                                {value}
                            </option>
                        ))}
                    </Select>

                    <Select
                        bgColor="white"
                        value={year}
                        readOnly
                        w="100px"
                        h="30px"
                        pr={0}
                        name="month"
                        border="0px"
                        fontSize="14px"
                        _focusVisible={{
                            outline: 'none',
                        }}
                        onChange={e => {
                            setYear(Number(e.target.value));
                            setSelectDate(`${e.target.value}-${month}-${day}`);
                        }}
                    >
                        {years().map(y => (
                            <option value={y} key={y}>
                                {y}
                            </option>
                        ))}
                    </Select>
                </Flex>
            </Box>
            <Button
                h="1px"
                color="#FFF"
                bg="#FFF"
                _focus={{
                    borderColor: '#FFF !important',
                }}
                _hover={{ bgColor: '#FFF !important' }}
                cursor="none"
            ></Button>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th
                            p={tableBoxPadding}
                            m={tableBoxPadding}
                            textAlign="center"
                            border="none"
                        >
                            S
                        </Th>
                        <Th
                            p={tableBoxPadding}
                            textAlign="center"
                            border="none"
                        >
                            M
                        </Th>
                        <Th
                            p={tableBoxPadding}
                            textAlign="center"
                            border="none"
                        >
                            T
                        </Th>
                        <Th
                            p={tableBoxPadding}
                            textAlign="center"
                            border="none"
                        >
                            W
                        </Th>
                        <Th
                            p={tableBoxPadding}
                            textAlign="center"
                            border="none"
                        >
                            T
                        </Th>
                        <Th
                            p={tableBoxPadding}
                            textAlign="center"
                            border="none"
                        >
                            F
                        </Th>
                        <Th
                            p={tableBoxPadding}
                            textAlign="center"
                            border="none"
                        >
                            S
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {results.map((result, index) => (
                        <Tr key={index}>
                            <Td
                                p={tableBoxPadding}
                                textAlign="center"
                                border="none"
                                onClick={() =>
                                    onClickDateChangeHandler(result[0])
                                }
                                bgColor={
                                    Number(result[0]) ==
                                    Number(selectedDate.split('-')[2])
                                        ? '#0077C0'
                                        : null
                                }
                                color={
                                    Number(result[0]) ==
                                    Number(selectedDate.split('-')[2])
                                        ? '#FFFFFF'
                                        : null
                                }
                                cursor="pointer"
                                borderRadius="50%"
                                fontSize="12px"
                            >
                                {result[0] === 0 ? '' : result[0]}
                            </Td>
                            <Td
                                p={tableBoxPadding}
                                textAlign="center"
                                border="none"
                                onClick={() =>
                                    onClickDateChangeHandler(result[1])
                                }
                                bgColor={
                                    Number(result[1]) ==
                                    Number(selectedDate.split('-')[2])
                                        ? '#0077C0'
                                        : null
                                }
                                color={
                                    Number(result[1]) ==
                                    Number(selectedDate.split('-')[2])
                                        ? '#FFFFFF'
                                        : null
                                }
                                cursor="pointer"
                                borderRadius="50%"
                                fontSize="12px"
                            >
                                {result[1] === 0 ? '' : result[1]}
                            </Td>
                            <Td
                                p={tableBoxPadding}
                                textAlign="center"
                                border="none"
                                onClick={() =>
                                    onClickDateChangeHandler(result[2])
                                }
                                bgColor={
                                    Number(result[2]) ==
                                    Number(selectedDate.split('-')[2])
                                        ? '#0077C0'
                                        : null
                                }
                                color={
                                    Number(result[2]) ==
                                    Number(selectedDate.split('-')[2])
                                        ? '#FFFFFF'
                                        : null
                                }
                                cursor="pointer"
                                borderRadius="50%"
                                fontSize="12px"
                            >
                                {result[2] === 0 ? '' : result[2]}
                            </Td>
                            <Td
                                p={tableBoxPadding}
                                textAlign="center"
                                border="none"
                                onClick={() =>
                                    onClickDateChangeHandler(result[3])
                                }
                                bgColor={
                                    Number(result[3]) ==
                                    Number(selectedDate.split('-')[2])
                                        ? '#0077C0'
                                        : null
                                }
                                color={
                                    Number(result[3]) ==
                                    Number(selectedDate.split('-')[2])
                                        ? '#FFFFFF'
                                        : null
                                }
                                cursor="pointer"
                                borderRadius="50%"
                                fontSize="12px"
                            >
                                {result[3] === 0 ? '' : result[3]}
                            </Td>
                            <Td
                                p={tableBoxPadding}
                                textAlign="center"
                                border="none"
                                onClick={() =>
                                    onClickDateChangeHandler(result[4])
                                }
                                bgColor={
                                    Number(result[4]) ==
                                    Number(selectedDate.split('-')[2])
                                        ? '#0077C0'
                                        : null
                                }
                                color={
                                    Number(result[4]) ==
                                    Number(selectedDate.split('-')[2])
                                        ? '#FFFFFF'
                                        : null
                                }
                                cursor="pointer"
                                borderRadius="50%"
                                fontSize="12px"
                            >
                                {result[4] === 0 ? '' : result[4]}
                            </Td>
                            <Td
                                p={tableBoxPadding}
                                textAlign="center"
                                border="none"
                                onClick={() =>
                                    onClickDateChangeHandler(result[5])
                                }
                                bgColor={
                                    Number(result[5]) ==
                                    Number(selectedDate.split('-')[2])
                                        ? '#0077C0'
                                        : null
                                }
                                color={
                                    Number(result[5]) ==
                                    Number(selectedDate.split('-')[2])
                                        ? '#FFFFFF'
                                        : null
                                }
                                cursor="pointer"
                                borderRadius="50%"
                                fontSize="12px"
                            >
                                {result[5] === 0 ? '' : result[5]}
                            </Td>
                            <Td
                                p={tableBoxPadding}
                                textAlign="center"
                                border="none"
                                onClick={() =>
                                    onClickDateChangeHandler(result[6])
                                }
                                bgColor={
                                    Number(result[6]) ==
                                    Number(selectedDate.split('-')[2])
                                        ? '#0077C0'
                                        : null
                                }
                                color={
                                    Number(result[6]) ==
                                    Number(selectedDate.split('-')[2])
                                        ? '#FFFFFF'
                                        : null
                                }
                                cursor="pointer"
                                borderRadius="50%"
                                fontSize="12px"
                            >
                                {result[6] === 0 ? '' : result[6]}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

SingleCalendar.propTypes = {
    results: PropTypes.array.isRequired,
    selectedDate: PropTypes.string.isRequired,
    setSelectDate: PropTypes.func.isRequired,
};

export default SingleCalendar;
