import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Select,
    Spacer,
    Text,
    useToast,
} from '@chakra-ui/react';
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import PropTypes from 'prop-types';

import DataLoader from '../../common/DataLoader';
import { buildTeamDetailsPath } from '../../constants/pathNameConstants';
import { TeamMemberRole } from '../../constants/teamManagementConstant';
import { useInformation } from '../../contexts/informationContext';
import EmployeeManagement from '../../service/employee-management';
import TeamManagement from '../../service/team-management';
import { addTeamMemberValidation } from '../../validation/dataValidation';

function TeamMemberAddModal({ isOpen, onClose, teamMembersSetCallBack }) {
    const { state } = useInformation();
    const {
        teamDetails: { teamId },
    } = state;
    const navigate = useNavigate();
    const toast = useToast();
    const [teamMemberRole, setTeamMemberRole] = useState(TeamMemberRole[0]);
    const [employeesNameAndEmail, setEmployeesNameAndEmail] = useState([]);
    const [employeeName, setEmployeeName] = useState('');
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: 'all',
        resolver: yupResolver(addTeamMemberValidation),
    });

    async function getEmployeeByNameHandler(name) {
        setEmployeesNameAndEmail([]);
        let uniqueSupervisorsNameAndEmail = new Set();

        if (name.length > 2) {
            try {
                const response = await EmployeeManagement.getEmployeesByName(
                    name
                );
                for (let i = 0; i < response.content.length; i++) {
                    uniqueSupervisorsNameAndEmail.add({
                        name: response.content[i].name,
                        email: response.content[i].email,
                    });
                }
                setEmployeesNameAndEmail([...uniqueSupervisorsNameAndEmail]);
            } catch (error) {
                toast({
                    title: 'Search fail',
                    description: 'Finding Employee has been failed.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    }

    const submit = async values => {
        let booleanFlagProceedForAdd = true;
        // Editing the value of supervisor Id
        if (employeesNameAndEmail.length === 0) {
            booleanFlagProceedForAdd = false;
            toast({
                title: 'Selection fail',
                description: 'Please select a employee',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } else {
            const employeeNameEmailPair = employeesNameAndEmail.find(
                employee => {
                    return employee.name === employeeName;
                }
            );
            if (employeeNameEmailPair) {
                values.email = employeeNameEmailPair.email;
            } else {
                booleanFlagProceedForAdd = false;
                toast({
                    title: 'Selection fail',
                    description: 'Please select a employee',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }

        if (booleanFlagProceedForAdd) {
            const payload = {
                teamId: teamId,
                members: [
                    {
                        email: values.email,
                        role: values.role,
                    },
                ],
            };
            setDataLoaderOpen(true);
            try {
                await TeamManagement.addTeamMember(payload);
                const response = await TeamManagement.findAllTeamMembers(
                    teamId
                );
                teamMembersSetCallBack(response.members);
                toast({
                    title: 'Successfully Added',
                    description: 'Member has been added successfully',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                setDataLoaderOpen(false);
                navigate(buildTeamDetailsPath(teamId));
                onClose();
            } catch (error) {
                setDataLoaderOpen(false);
                toast({
                    title: 'Add fail',
                    description: error.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
                onClose();
            }
        }
    };

    if (isDataLoaderOpen) return <DataLoader isOpen={isDataLoaderOpen} />;

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent
                maxW={{ lg: '550px', md: '500px', sm: '500px', base: 'full' }}
                mx={2}
                bgColor="#FFF"
                color="#464646"
                align="center"
            >
                <ModalCloseButton />
                <ModalBody>
                    <Flex pt={8} direction="column" fontSize="14px">
                        <Text as="b" fontSize="24px">
                            {' '}
                            Add Member{' '}
                        </Text>

                        <Box my="16px" width={{ base: '96%', lg: '486px' }}>
                            <hr />
                        </Box>

                        <form onSubmit={handleSubmit(submit)}>
                            <FormControl mt={6}>
                                <FormLabel
                                    fontSize="14px"
                                    htmlFor="name"
                                    color="#464646"
                                >
                                    Employee Name
                                </FormLabel>
                                <Flex direction={{ lg: 'row', base: 'column' }}>
                                    <Flex
                                        direction={{
                                            lg: 'column',
                                            base: 'column',
                                        }}
                                        width={{ base: '96%', lg: '486px' }}
                                    >
                                        <AutoComplete openOnFocus>
                                            <AutoCompleteInput
                                                variant="filled"
                                                {...register('name')}
                                                placeholder="Search..."
                                                border="1px"
                                                borderColor="#DDDDDD"
                                                bgColor="#FFFFFF"
                                                color="#464646"
                                                isInvalid={errors.name}
                                                _focus={{
                                                    borderColor:
                                                        '#DDDDDD !important',
                                                }}
                                                onChange={e => {
                                                    getEmployeeByNameHandler(
                                                        e.target.value
                                                    );
                                                    setEmployeeName(
                                                        e.target.value
                                                    );
                                                    register('name').onChange(
                                                        e
                                                    );
                                                }}
                                            />
                                            <AutoCompleteList>
                                                {employeesNameAndEmail.map(
                                                    (employee, s_id) => (
                                                        <AutoCompleteItem
                                                            key={`option-${s_id}`}
                                                            value={
                                                                employee.name
                                                            }
                                                            onClick={() =>
                                                                setEmployeeName(
                                                                    employee.name
                                                                )
                                                            }
                                                        >
                                                            {employee.name}
                                                        </AutoCompleteItem>
                                                    )
                                                )}
                                            </AutoCompleteList>
                                        </AutoComplete>
                                    </Flex>
                                </Flex>
                                {errors.name && (
                                    <Text
                                        color="#E53E3E"
                                        mt={2}
                                        fontSize="14px"
                                        maxWidth={{ base: '96%', lg: '70%' }}
                                        status="error"
                                    >
                                        {errors.name && errors.name.message}
                                    </Text>
                                )}
                            </FormControl>

                            <FormControl mt={6}>
                                <FormLabel
                                    fontSize="14px"
                                    htmlFor="role"
                                    color="#464646"
                                >
                                    Role
                                </FormLabel>
                                <Flex direction={{ lg: 'row', base: 'column' }}>
                                    <Flex
                                        direction={{
                                            lg: 'column',
                                            base: 'column',
                                        }}
                                        width={{ base: '96%', lg: '486px' }}
                                    >
                                        <Select
                                            {...register('role')}
                                            value={teamMemberRole}
                                            border="1px"
                                            borderColor="#DDDDDD"
                                            color="#464646"
                                            isInvalid={errors.role}
                                            _focus={{
                                                borderColor:
                                                    '#DDDDDD !important',
                                            }}
                                            _placeholder={{
                                                color: '#DDDDDD !important',
                                            }}
                                            onChange={e => {
                                                setTeamMemberRole(
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            {TeamMemberRole.map(
                                                (value, index) => (
                                                    <option
                                                        value={value}
                                                        key={index}
                                                    >
                                                        {value}
                                                    </option>
                                                )
                                            )}
                                        </Select>
                                    </Flex>
                                </Flex>
                                {errors.role && (
                                    <Text
                                        color="#E53E3E"
                                        mt={2}
                                        fontSize="14px"
                                        maxWidth={{ base: '96%', lg: '70%' }}
                                        status="error"
                                    >
                                        {errors.role && errors.role.message}
                                    </Text>
                                )}
                            </FormControl>

                            <Flex
                                direction="row"
                                mt={6}
                                mb="54px"
                                w={{ lg: '486px', base: '96%' }}
                            >
                                <Text as="u" cursor="pointer" onClick={onClose}>
                                    Cancel
                                </Text>
                                <Spacer />
                                <Button
                                    onSubmit={submit}
                                    w="119px"
                                    h="30px"
                                    bgColor="#0077C0"
                                    color="white"
                                    type="submit"
                                    disabled={!isValid}
                                    _focus={{
                                        borderColor: 'none',
                                    }}
                                    _hover={{
                                        color: 'white',
                                        bgColor: '#0077C0',
                                    }}
                                    _disabled={{
                                        cursor: 'not-allowed',
                                        bgColor: '#DDDDDD !important',
                                        color: '#9A9A9A !important',
                                    }}
                                >
                                    Add
                                </Button>
                            </Flex>
                        </form>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

TeamMemberAddModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    teamMembersSetCallBack: PropTypes.func.isRequired,
};

export default TeamMemberAddModal;
