import { getShortFormDate } from './utils';

export const makeColumnBarData = datas => {
    const getDataInHours = attendances => {
        let workingTimeInHours = [];
        for (let i = 0; i < attendances.length; i++) {
            workingTimeInHours.push(
                Number.parseFloat(attendances[i].minutes / 60).toFixed(2)
            );
        }
        return workingTimeInHours;
    };

    const getCategories = (dimension, attendances) => {
        let categories = [];
        for (let i = 0; i < attendances.length; i++) {
            if (dimension === 'Month') {
                categories.push(
                    getShortFormDate(attendances[i].identifier).slice(0, 3)
                );
            } else {
                categories.push(getShortFormDate(attendances[i].identifier));
            }
        }
        return categories;
    };

    return {
        series: [
            {
                name: 'Working hours',
                data: getDataInHours(datas.attendances),
            },
        ],
        options: {
            dimension: datas.dimension,
            chart: {
                height: 350,
                type: 'bar',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 0,
                    columnWidth: datas.dimension === 'Day' ? '20px' : '50px',
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                    color: '#A1DBFF',
                },
            },
            dataLabels: {
                enabled: false,
                formatter: function (val) {
                    return val;
                },
                style: {
                    fontSize: '8px',
                    colors: ['#A1DBFF'],
                },
            },

            xaxis: {
                categories: getCategories(datas.dimension, datas.attendances),
                position: 'bottom',
                tooltip: {
                    enabled: false,
                },
                axisTicks: { show: false },
                tickPlacement: 'between',
                floating: false,
                labels: {
                    style: {
                        fontSize: '10px',
                    },
                },
            },
            yaxis: {
                labels: {
                    show: true,
                    formatter: function (val) {
                        return val;
                    },
                },
            },
            fill: {
                colors: ['#A1DBFF'],
            },
        },
    };
};
