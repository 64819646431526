import 'react-calendar/dist/Calendar.css';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';

import './style.css';

const DateRange = props => {
    return <DateRangePicker {...props} />;
};

export default DateRange;
