import React from 'react';

import {
    Box,
    Flex,
    Popover,
    PopoverTrigger,
    Image,
    PopoverContent,
    PopoverBody,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import calendar from '../assets/icons/date.svg';
import * as color from '../constants/colorCode';
import { makeCalendarForDateRange } from '../helper/make-calendar-for-date-range';
import SingleCalendar from './SingleCalendar';

const popoverContentWidth = ['300px', '550px', 'auto'];

const DateRangePopover = ({
    startDate,
    endDate,
    startDateChangeHandler,
    endDateChangeHandler,
    isOpen,
    open,
    close,
}) => {
    return (
        <Popover
            returnFocusOnClose={false}
            isOpen={isOpen}
            onClose={close}
            closeOnBlur={true}
        >
            <PopoverTrigger>
                <Image onClick={open} w="22px" src={calendar} />
            </PopoverTrigger>
            <PopoverContent w={popoverContentWidth}>
                {/* <PopoverArrow bg="#0077C0" /> */}
                <PopoverBody
                    border={`8px solid ${color.POPOVER_CONTENT_SIDE_COLOR}`}
                    p={0}
                    borderRadius="5px"
                >
                    <Box
                        bg={color.POPOVER_CONTENT_SIDE_COLOR}
                        m="-1px"
                        h="10px"
                        w="100.7%"
                        align="center"
                    >
                        <Box
                            pt="0px"
                            w="60px"
                            bgColor={color.POPOVER_CONTENT_MIDDLE_BAR_COLOR}
                            borderRadius="10px"
                            border={`2px solid ${color.POPOVER_CONTENT_MIDDLE_BAR_COLOR}`}
                        ></Box>
                    </Box>
                    <Box p={['4px', '2px', '2px']}>
                        <Flex direction={['column', 'row', 'row']}>
                            <SingleCalendar
                                results={makeCalendarForDateRange(startDate)}
                                selectedDate={startDate}
                                setSelectDate={startDateChangeHandler}
                            />
                            <SingleCalendar
                                results={makeCalendarForDateRange(endDate)}
                                selectedDate={endDate}
                                setSelectDate={endDateChangeHandler}
                            />
                        </Flex>
                    </Box>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

DateRangePopover.propTypes = {
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    startDateChangeHandler: PropTypes.func.isRequired,
    endDateChangeHandler: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
};

export default DateRangePopover;
