import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Avatar, Box, Flex, Td, Text, Tr, WrapItem } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';

import CommonTooltip from '../../common/CommonTooltip';
import * as color from '../../constants/colorCode';
import {
    EMPLOYEE_DESIGNATION_CHARACTER_LIMIT,
    EMPLOYEE_NAME_CHARACTER_LIMIT,
} from '../../constants/employeeManagementConstant';
import { buildLeavePath } from '../../constants/pathNameConstants';
import getShortFormText from '../../helper/get-short-form-text';

const nameColWidth = '220px';
const designationColWidth = '210px';
const partnerColWidth = '70px';
const totalLeaveColWidth = ['80px', '80px'];
const unplannedColWidth = ['120px', '120px', '120px', '100px', '100px'];
const monthlyAverageColWidth = ['120px', '120px', '120px', '90px', '90px'];
const yearlyForecastColWidth = ['110px', '110px'];
const leaveComparisonColWidth = ['120px', '120px', '120px', '110px', '110px'];
const API_URL = process.env.REACT_APP_SERVER_URL;
const nameCharLimit = EMPLOYEE_NAME_CHARACTER_LIMIT - 3;
const designationCharLimit = EMPLOYEE_DESIGNATION_CHARACTER_LIMIT - 5;
const TABLE_DATA_PADDING = ['0px', '15px'];

const getYearlyForecastColor = (yearlyForecast, companyAverage) => {
    if (yearlyForecast < companyAverage)
        return color.YEARLY_FORECAST_GREEN_COLOR;
    else if (yearlyForecast > companyAverage)
        return color.YEARLY_FORECAST_RED_COLOR;
    else return color.YEARLY_FORECAST_YELLOW_COLOR;
};

const getleaveComparisonColor = (yearlyForecast, companyAverage) => {
    if (yearlyForecast <= companyAverage)
        return color.YEARLY_FORECAST_GREEN_COLOR;
    else return color.YEARLY_FORECAST_RED_COLOR;
};

const LeavesReportTableRow = ({ result, index }) => {
    const navigate = useNavigate();

    return (
        <Tr
            bgColor={index & 1 ? color.ODD_ROW_COLOR : color.EVEN_ROW_COLOR}
            onClick={() => {
                navigate(buildLeavePath(result.employeeId));
            }}
            cursor="pointer"
            _hover={{
                bg: color.ROW_HOVER_COLOR,
                color: color._FFFFFF,
            }}
        >
            <Td p={2} minW={nameColWidth} w={nameColWidth}>
                <Flex direction="row">
                    <WrapItem>
                        <Avatar
                            size="sm"
                            src={`${API_URL}${result.profilePictureUrl}`}
                            name={result.employeeName}
                        />{' '}
                    </WrapItem>
                    <Text pl="10px" pt="6px" align="center">
                        {result.employeeName === null ||
                        result.employeeName.length === 0 ? (
                            <Text>{''}</Text>
                        ) : (
                            <CommonTooltip
                                label={result.employeeName}
                                disabled={
                                    result.employeeName.length <= nameCharLimit
                                }
                            >
                                {getShortFormText(
                                    result.employeeName,
                                    nameCharLimit
                                )}
                            </CommonTooltip>
                        )}
                    </Text>
                </Flex>
            </Td>
            <Td
                p={TABLE_DATA_PADDING}
                minW={designationColWidth}
                w={designationColWidth}
            >
                <CommonTooltip
                    label={result.designation}
                    disabled={
                        result.designation.trim().length <= designationCharLimit
                    }
                >
                    {getShortFormText(result.designation, designationCharLimit)}
                </CommonTooltip>
            </Td>
            <Td
                p={TABLE_DATA_PADDING}
                minW={partnerColWidth}
                w={partnerColWidth}
            >
                {result.partner.name}
            </Td>
            <Td
                p={TABLE_DATA_PADDING}
                minW={totalLeaveColWidth}
                w={totalLeaveColWidth}
            >
                {result.totalLeave}
            </Td>
            <Td
                p={TABLE_DATA_PADDING}
                minW={unplannedColWidth}
                w={unplannedColWidth}
            >
                {result.unplannedLeave}
            </Td>
            <Td
                p={TABLE_DATA_PADDING}
                minW={monthlyAverageColWidth}
                w={monthlyAverageColWidth}
            >
                {result.monthlyAverageLeave}
            </Td>
            <Td
                p={TABLE_DATA_PADDING}
                minW={yearlyForecastColWidth}
                w={yearlyForecastColWidth}
            >
                <Box
                    w="54px"
                    h="22px"
                    color={color._FFFFFF}
                    bg={getYearlyForecastColor(
                        result.yearlyForecastedLeave,
                        result.companyAverageLeave
                    )}
                    fontSize="12px"
                    borderRadius="4px"
                    align="center"
                >
                    {result.yearlyForecastedLeave}
                </Box>
            </Td>
            <Td
                p={TABLE_DATA_PADDING}
                minW={leaveComparisonColWidth}
                w={leaveComparisonColWidth}
                color={getleaveComparisonColor(
                    result.yearlyForecastedLeave,
                    result.companyAverageLeave
                )}
            >
                {result.leaveComparison > 0 ? '+' : null}
                {result.leaveComparison}%
            </Td>
        </Tr>
    );
};

LeavesReportTableRow.propTypes = {
    result: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    partnersName: PropTypes.string.isRequired,
};

export default LeavesReportTableRow;
