import { MAX_DAY_OF_EACH_MONTH, months } from '../constants/commonConstant';
import { isLeapYear } from './utils';

const pushedRowIntoSelectedMonth = (obj, row, monthNo) => {
    obj[months[monthNo].value].push(row);
};

const fillAllTheFirstRowOfAllMonth = (
    obj,
    firstDateDayOfAllMonth,
    lastDateOfFirstRows
) => {
    for (let monthNo = 0; monthNo < firstDateDayOfAllMonth.length; monthNo++) {
        let firstRow = [0, 0, 0, 0, 0, 0, 0];
        let indexOfFirstDate;
        for (let j = 0; j < firstRow.length; j++) {
            indexOfFirstDate = j;
            if (j == firstDateDayOfAllMonth[monthNo]) {
                firstRow[j] = 1;
                indexOfFirstDate = j;
                break;
            }
        }

        let val = 2; // first row date value
        for (let j = indexOfFirstDate + 1; j < firstRow.length; j++) {
            firstRow[j] = val;
            val += 1;
        }
        lastDateOfFirstRows[monthNo] = val - 1;

        pushedRowIntoSelectedMonth(obj, firstRow, monthNo);
    }
};

export const fillWholeCalendar = () => {
    let calendar = {
        January: [],
        February: [],
        March: [],
        April: [],
        May: [],
        June: [],
        July: [],
        August: [],
        September: [],
        October: [],
        November: [],
        December: [],
    };

    let firstDateDayOfAllMonth = [];
    let lastDateOfFirstRows = [];

    let todaysDate = new Date();
    let currentYear = todaysDate.getFullYear();
    let rows = 6,
        cols = 7;

    // get all the day of first date of month
    for (let m = 1; m <= 12; m++) {
        firstDateDayOfAllMonth.push(new Date(`${currentYear}/${m}/1`).getDay());
    }

    fillAllTheFirstRowOfAllMonth(
        calendar,
        firstDateDayOfAllMonth,
        lastDateOfFirstRows
    );

    for (let monthNo = 0; monthNo < 12; monthNo++) {
        let value = lastDateOfFirstRows[monthNo]; // value => start date from second row
        let maxDayOfEachMonth;
        if (monthNo === 1)
            maxDayOfEachMonth = isLeapYear(currentYear) ? 29 : 28;
        else maxDayOfEachMonth = MAX_DAY_OF_EACH_MONTH[monthNo];

        for (let row = 1; row < rows && value <= maxDayOfEachMonth; ++row) {
            let eachRowsDate = [];
            for (let col = 0; col < cols; ++col) {
                value += 1;
                if (value > maxDayOfEachMonth) eachRowsDate.push(0);
                else eachRowsDate.push(value);
            }
            pushedRowIntoSelectedMonth(calendar, eachRowsDate, monthNo);
        }
    }

    return calendar;
};
