import React, { useState } from 'react';

import {
    Popover,
    PopoverTrigger,
    Image,
    PopoverContent,
    PopoverBody,
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import calendar from '../../assets/icons/date.svg';

const TABLE_FIELD_PADDING_X = '16px';
const TABLE_FIELD_PADDING_Y = '8px';

const LeaveReportDateRangePicker = ({ options, onSelectHandler }) => {
    const [isOpen, setIsOpen] = useState(false);
    const open = () => setIsOpen(!isOpen);
    const close = () => setIsOpen(!isOpen);

    const onClickHandler = e => {
        onSelectHandler(e.target.id);
    };

    return (
        <Popover
            returnFocusOnClose={false}
            isOpen={isOpen}
            onClose={close}
            closeOnBlur={true}
            placement="bottom-start"
        >
            <PopoverTrigger>
                <Image onClick={open} w="22px" src={calendar} />
            </PopoverTrigger>

            <PopoverContent
                w="105px"
                _focusVisible={{
                    outline: 'none',
                }}
                onClick={close}
            >
                <PopoverBody p={0}>
                    <TableContainer
                        borderRadius="4px"
                        border="1px"
                        borderColor="#BDBDBD"
                    >
                        <Table fontSize="12px">
                            <Tbody onClick={onClickHandler}>
                                {options.map(option => (
                                    <Tr key={option.id}>
                                        <Td
                                            id={option.id}
                                            px={TABLE_FIELD_PADDING_X}
                                            py={TABLE_FIELD_PADDING_Y}
                                            borderBottom="2px"
                                            borderColor="#E0E0E0"
                                        >
                                            {option.label}
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

LeaveReportDateRangePicker.propTypes = {
    options: PropTypes.array.isRequired,
    onSelectHandler: PropTypes.func.isRequired,
};

export default LeaveReportDateRangePicker;
