// urls
export const GoogleUrl = process.env.REACT_APP_GOOGLE_URL;
// Key
export const INFORMATION_KEY = 'information';
export const ACCESS_TOKEN_KEY = 'accessToken';
export const SELECT_KEY = 'select';
export const INPUT_KEY = 'input';
export const EMPLOYEE_NAME_KEY = 'Employee Name';
export const ROLE_DEPARTMENT_KEY = 'Role/department';
export const PARTNER_KEY = 'Partner';

export const TABLE_FIELD_PADDING = 3;
export const CALENDAR_TABLE_FIELD_PADDING = 1;

export const years = () => {
    let currentYear = new Date().getFullYear() + 1;
    let minYear = currentYear - 2018 + 1;
    let years = [];
    for (let i = 0; i < minYear; i++) {
        years.push(currentYear - i);
    }
    return years;
};

export const days = () => {
    let days = [];
    for (let i = 1; i <= 31; i++) {
        if (i === 1 || i === 21 || i === 31)
            days.push({ id: i, value: i + 'st' });
        else if (i === 2 || i === 22) days.push({ id: i, value: i + 'nd' });
        else if (i === 3 || i === 23) days.push({ id: i, value: i + 'rd' });
        else days.push({ id: i, value: i + 'th' });
    }

    return days;
};

export const months = [
    { key: '01', value: 'January', bdValue: 'জানুয়ারি' },
    { key: '02', value: 'February', bdValue: 'ফেব্রুয়ারী' },
    { key: '03', value: 'March', bdValue: 'মার্চ' },
    { key: '04', value: 'April', bdValue: 'এপ্রিল' },
    { key: '05', value: 'May', bdValue: 'মে' },
    { key: '06', value: 'June', bdValue: 'জুন' },
    { key: '07', value: 'July', bdValue: 'জুলাই' },
    { key: '08', value: 'August', bdValue: 'আগষ্ট' },
    { key: '09', value: 'September', bdValue: 'সেপ্টেম্বর' },
    { key: '10', value: 'October', bdValue: 'অক্টোবর' },
    { key: '11', value: 'November', bdValue: 'নভেম্বর' },
    { key: '12', value: 'December', bdValue: 'ডিসেম্বর' },
];

export const MAX_DAY_OF_EACH_MONTH = [
    31, 0, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
];
