import React from 'react';

import { Thead, Tr, Th } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import * as color from '../constants/colorCode';
import { TABLE_FIELD_PADDING } from '../constants/commonConstant';

const CommonTableHeading = ({ tableHeadings }) => {
    return (
        <Thead bgColor={color.TABLE_HEADING_BG_COLOR}>
            <Tr>
                {tableHeadings.map((heading, index) => {
                    return (
                        <Th
                            key={index}
                            p={TABLE_FIELD_PADDING}
                            color={color.TABLE_HEADING_TEXT_COLOR}
                            textTransform="capitalize"
                            borderTopLeftRadius={index === 0 && '4px'}
                            borderTopRightRadius={
                                index === tableHeadings.length - 1 && '4px'
                            }
                        >
                            {heading}
                        </Th>
                    );
                })}
            </Tr>
        </Thead>
    );
};

CommonTableHeading.propTypes = {
    tableHeadings: PropTypes.array.isRequired,
};

export default CommonTableHeading;
