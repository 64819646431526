import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    Select,
    Spacer,
    Text,
    useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import CustomBreadcrumb from '../../common/CustomBreadcrumb';
import DataLoader from '../../common/DataLoader';
import PageTitle from '../../common/PageTitle';
import AdminManagement from '../../service/admin-management';
import { addAdminValidation } from '../../validation/dataValidation';

const AddAdmin = () => {
    const PAGE_TITLE = 'Add Admin';
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);
    const toast = useToast();
    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, isValid },
    } = useForm({
        mode: 'all',
        resolver: yupResolver(addAdminValidation),
        defaultValues: {
            email: '',
            name: '',
            role: 'ADMIN',
        },
    });

    const onSubmit = async data => {
        setDataLoaderOpen(true);
        try {
            await AdminManagement.addNewAdmin(data);
            toast({
                title: 'Successfully added',
                description: 'Admin has been added successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            reset({
                email: '',
                name: '',
                role: 'ADMIN',
            });
            setDataLoaderOpen(false);
        } catch (error) {
            setDataLoaderOpen(false);
            toast({
                title: 'Add fail',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    if (isDataLoaderOpen) return <DataLoader />;

    return (
        <>
            <CustomBreadcrumb allBreadcrumbData={['Home', 'Add Admin']} />
            <Box direction="row" my="16px">
                <PageTitle title={PAGE_TITLE} />
            </Box>
            <Flex mt={10} direction="column" ml={{ lg: '25%', base: '2%' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl mb="4" isInvalid={errors.email}>
                        <FormLabel
                            fontSize="14px"
                            htmlFor="email"
                            color="#464646"
                        >
                            Email
                        </FormLabel>
                        <Flex direction={{ lg: 'row', base: 'column' }}>
                            <Flex
                                direction={{ lg: 'column', base: 'column' }}
                                width={{ base: '96%', lg: '486px' }}
                            >
                                <Input
                                    {...register('email')}
                                    placeholder="Email"
                                    bgColor="white"
                                    color="#464646"
                                    border="1px"
                                    width="100%"
                                    borderColor="#DDDDDD"
                                    _focus={{
                                        borderColor: '#DDDDDD !important',
                                    }}
                                />
                                <FormHelperText>
                                    Email address (Required)
                                </FormHelperText>
                                <FormErrorMessage>
                                    {errors.email && errors.email.message}
                                </FormErrorMessage>
                            </Flex>
                        </Flex>
                    </FormControl>

                    <FormControl mb="4" isInvalid={errors.name}>
                        <FormLabel
                            fontSize="14px"
                            htmlFor="name"
                            color="#464646"
                        >
                            Name
                        </FormLabel>
                        <Flex direction={{ lg: 'row', base: 'column' }}>
                            <Flex
                                direction={{ lg: 'column', base: 'column' }}
                                width={{ base: '96%', lg: '486px' }}
                            >
                                <Input
                                    {...register('name')}
                                    placeholder="Name"
                                    bgColor="white"
                                    color="#464646"
                                    border="1px"
                                    width="100%"
                                    borderColor="#DDDDDD"
                                    _focus={{
                                        borderColor: '#DDDDDD !important',
                                    }}
                                />
                                <FormHelperText>Name (Required)</FormHelperText>
                                <FormErrorMessage>
                                    {errors.name && errors.name.message}
                                </FormErrorMessage>
                            </Flex>
                        </Flex>
                    </FormControl>

                    <FormControl mb="4">
                        <FormLabel
                            fontSize="14px"
                            htmlFor="role"
                            color="#464646"
                        >
                            Admin role
                        </FormLabel>
                        <Flex direction={{ lg: 'row', base: 'column' }}>
                            <Flex
                                direction={{ lg: 'column', base: 'column' }}
                                width={{ base: '96%', lg: '486px' }}
                            >
                                <Select
                                    {...register('role')}
                                    defaultValue="ADMIN"
                                    border="1px"
                                    borderColor="#DDDDDD"
                                    color="#464646"
                                    width="100%"
                                    isInvalid={errors.role}
                                    _focus={{
                                        borderColor: '#DDDDDD !important',
                                    }}
                                    _placeholder={{
                                        color: '#DDDDDD !important',
                                    }}
                                >
                                    <option value="ADMIN">ADMIN</option>
                                    <option value="SUPER_ADMIN">
                                        SUPER_ADMIN
                                    </option>
                                </Select>
                                <FormHelperText>
                                    Role: ADMIN, SUPER_ADMIN
                                </FormHelperText>
                                <FormErrorMessage>
                                    {errors.role && errors.role.message}
                                </FormErrorMessage>
                            </Flex>
                        </Flex>
                    </FormControl>

                    <Flex
                        direction="row"
                        mt={6}
                        mb="54px"
                        w={{ lg: '486px', base: '96%' }}
                    >
                        <Text
                            as="u"
                            cursor="pointer"
                            //onClick={() => navigate("/teams")}
                            display="none"
                        >
                            Cancel
                        </Text>
                        <Spacer />
                        <Button
                            onSubmit={onSubmit}
                            w="119px"
                            h="30px"
                            bgColor="#0077C0"
                            color="white"
                            type="submit"
                            disabled={!isValid}
                            _focus={{
                                borderColor: 'none',
                            }}
                            _hover={{ color: 'white', bgColor: '#0077C0' }}
                            _disabled={{
                                cursor: 'not-allowed',
                                bgColor: '#DDDDDD !important',
                                color: '#9A9A9A !important',
                            }}
                        >
                            Add
                        </Button>
                    </Flex>
                </form>
            </Flex>
        </>
    );
};

export default AddAdmin;
