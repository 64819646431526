import Service from './index';

const REPORT_SEARCH_PATH = 'admin/report/leave';

export default class ReportManagement {
    static async leaveReportDetails(payload) {
        return Service.post(`${REPORT_SEARCH_PATH}/details`, payload).then(
            response => response.data
        );
    }

    static async leaveReportDownload(payload) {
        return Service.post(`${REPORT_SEARCH_PATH}/download`, payload).then(
            response => response.data
        );
    }

    static async individualLeaveReportDetails(
        startFrom,
        endAt,
        page,
        size,
        employeeId
    ) {
        const params = new URLSearchParams({
            startFrom: startFrom,
            endAt: endAt,
            page: page,
            size: size,
        }).toString();

        return Service.get(
            `${REPORT_SEARCH_PATH}/details/${employeeId}?${params}`
        ).then(response => response.data);
    }

    static async individualLeaveReportDownload(employeeId, startFrom, endAt) {
        const params = new URLSearchParams({
            startFrom,
            endAt,
        }).toString();

        return Service.get(
            `${REPORT_SEARCH_PATH}/download/${employeeId}?${params}`
        ).then(response => response.data);
    }
}
