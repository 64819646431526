import React from 'react';

import { Box, Flex, Image, Text, Grid, GridItem } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';

import closeIcon from '../../assets/icons/close.svg';

const employeeNameBoxWidth = 'auto';
const designationBoxWidth = 'auto';
const templateOneColumns = 'repeat(1, 1fr)';
const templateTwoColumns = 'repeat(1, 1fr)';

const FilteredData = ({
    nameList,
    removeNameFromNameListQueryParameter,
    selectedPartner,
    setSelectedPartner,
    designationList,
    removeDesignationFromDesignationListQueryParameter,
}) => {
    const getGridItemDetails = (onClickHandler, text, width = null) => {
        return (
            <Flex
                mx="1px"
                direction="row"
                bg="#DADADA"
                borderRadius="4px"
                my="3px"
            >
                <Image
                    ml="4px"
                    src={closeIcon}
                    onClick={onClickHandler}
                    cursor="pointer"
                />

                <Text p="5px" w={width}>
                    {text}
                </Text>
            </Flex>
        );
    };

    return (
        <Box w={{ lg: '100%', base: '95%' }}>
            <Flex direction={{ lg: 'row', base: 'column' }} mt="10px">
                <Box>
                    <Grid
                        templateColumns={{
                            lg: templateTwoColumns,
                            base: templateOneColumns,
                        }}
                        m="0px"
                    >
                        {nameList.map(employee => (
                            <GridItem key={employee.employeeId} m="0px">
                                {getGridItemDetails(
                                    () =>
                                        removeNameFromNameListQueryParameter(
                                            employee.employeeId
                                        ),
                                    employee.employeeName,
                                    employeeNameBoxWidth
                                )}
                            </GridItem>
                        ))}
                    </Grid>
                </Box>

                <Box mx="6px" />

                <Box>
                    <Grid templateColumns={templateOneColumns}>
                        {designationList.map(designation => (
                            <GridItem key={designation}>
                                {getGridItemDetails(
                                    () => {
                                        removeDesignationFromDesignationListQueryParameter(
                                            designation
                                        );
                                    },
                                    designation,
                                    designationBoxWidth
                                )}
                            </GridItem>
                        ))}
                    </Grid>
                </Box>

                <Box mx="6px" />
                <Box>
                    {selectedPartner
                        ? getGridItemDetails(
                              () => setSelectedPartner(null),
                              selectedPartner
                          )
                        : null}
                </Box>
            </Flex>
        </Box>
    );
};

FilteredData.propTypes = {
    nameList: PropTypes.array.isRequired,
    removeNameFromNameListQueryParameter: PropTypes.func.isRequired,
    selectedPartner: PropTypes.string,
    setSelectedPartner: PropTypes.func.isRequired,
    designationList: PropTypes.array.isRequired,
    removeDesignationFromDesignationListQueryParameter:
        PropTypes.func.isRequired,
};

export default FilteredData;
