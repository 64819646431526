import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import { Box } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';

import EmailDecoder from '../helper/email-decoder';
import Menubar from './Menubar';
import Navbar from './Navbar';

function ProtectedRoute({ children }) {
    const email = EmailDecoder();
    const [authed] = useState(email !== null);

    if (authed) {
        return (
            <>
                <Navbar />
                <Box h="100vh" display="flex" overflow="hidden">
                    {/* <Sidebar /> */}
                    <Menubar />
                    {/* main content */}
                    <Box w="full" pt={4} overflowY="auto">
                        <Box mx={['0px', '4px', '8px', '16px']}>{children}</Box>
                    </Box>
                </Box>
            </>
        );
    }

    return <Navigate to="/login" replace={true} />;
}

ProtectedRoute.propTypes = {
    children: PropTypes.object.isRequired,
};

export default ProtectedRoute;
