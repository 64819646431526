import { useEffect, useState } from 'react';

import {
    Avatar,
    Box,
    Flex,
    Heading,
    HStack,
    Image,
    Spacer,
    Table,
    Tbody,
    Td,
    Text,
    Tr,
    WrapItem,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import editIcon from '../../../assets/icons/edit.svg';
import CommonTableHeading from '../../../common/CommonTableHeading';
import CommonTooltip from '../../../common/CommonTooltip';
import getShortFormText from '../../../helper/get-short-form-text';
import { getHumanReadableDate } from '../../../helper/utils';

const PersonalInfo = ({
    results,
    partnerList,
    supervisor,
    isEmployeeEditModalOpen,
    setEmployeeEditModalOpen,
}) => {
    const API_URL = process.env.REACT_APP_SERVER_URL;
    const TABLE_HEADINGS = ['Personal Info'];
    const [partnerName, setPartnerName] = useState('N/A');
    const [supervisorName, setSupervisorName] = useState('N/A');
    const howManyContactNumber = 1;

    useEffect(() => {
        const partner = partnerList.find(p => p.id === results.partnerId);
        setPartnerName(partner.name);
        setSupervisorName(supervisor.name);
    }, [partnerName, supervisorName, isEmployeeEditModalOpen]);

    const getContactNumber = contacts => {
        let selfContacts = '';
        //will show only SELF contacts
        for (let i = 0; i < contacts.length; i++) {
            if (
                contacts[i].relationship === 'OWN' &&
                selfContacts.length < howManyContactNumber * 11
            ) {
                if (selfContacts.length > 0) selfContacts += ', ';
                selfContacts += contacts[i].contact;
            }
        }
        if (selfContacts.length === 0) selfContacts += 'N/A';
        return selfContacts;
    };

    const LEFT_SIDE_HEADINGS = [
        'Email',
        'Employee Id',
        'Contact Number',
        'Date of Joining',
        'Designation',
        'Status',
        'Parner Name',
        'Supervisor Name',
    ];

    const RIGHT_SIDE_VALUES = [
        results.email,
        results.employeeId,
        getContactNumber(results.contacts),
        getHumanReadableDate(results.joiningDate),
        results.designation,
        results.status,
        partnerName,
        supervisorName,
    ];

    const getEmployeeNameAndDesignation = (
        employeeName,
        employeeDesignation
    ) => {
        return (
            <>
                <HStack spacing={5}>
                    <Heading fontSize="22px" color="#4F4F4F">
                        {employeeName}
                    </Heading>
                    <Image
                        onClick={() => setEmployeeEditModalOpen(true)}
                        cursor="pointer"
                        src={editIcon}
                    />
                </HStack>
                <Text lineHeight="30px" fontSize="16px" color="#333333">
                    {employeeDesignation}
                </Text>
            </>
        );
    };

    const getEmployeeDetailsLeftSideHeadings = heading => {
        return (
            <Text lineHeight="30px" color="#828282">
                {heading}
            </Text>
        );
    };

    const getEmployeeDetailsRightSideValues = value => {
        return (
            <Text lineHeight="30px" fontWeight="600">
                {value}
            </Text>
        );
    };

    const getEmployeeDetailsMoreInfo = () => {
        return (
            <HStack mt="12px" spacing="64px" fontSize="14px">
                <Box w="150px">
                    {LEFT_SIDE_HEADINGS.map((heading, index) => {
                        return (
                            <Box key={index}>
                                {getEmployeeDetailsLeftSideHeadings(heading)}
                            </Box>
                        );
                    })}
                </Box>
                <Box w="370px">
                    {RIGHT_SIDE_VALUES.map((value, index) => {
                        return (
                            <Box key={index}>
                                {getEmployeeDetailsRightSideValues(value)}
                            </Box>
                        );
                    })}
                </Box>
            </HStack>
        );
    };

    const getPersonalInfoLeftSideData = () => {
        return (
            <Box>
                <Flex>
                    <WrapItem>
                        <Avatar
                            w="53px"
                            h="53px"
                            name={results.name}
                            src={`${API_URL}${results.profilePictureUrl}`}
                        />
                    </WrapItem>
                    <Box ml={['15px', '18px', '18px', '20px']}>
                        {getEmployeeNameAndDesignation(
                            results.name,
                            results.designation
                        )}
                        {getEmployeeDetailsMoreInfo()}
                    </Box>
                </Flex>
            </Box>
        );
    };

    const getPersonalInfoRightSideData = () => {
        return (
            <Box w="200px" fontSize="12px">
                <Box ml="24%">
                    <Flex
                        pt={0}
                        top={0}
                        direction="column"
                        align="left"
                        justify="left"
                    >
                        <Flex direction="row" w="auto">
                            <Text as="b">Joining Date{': '}</Text>
                            <Text pl="5px">{results.joiningDate}</Text>
                        </Flex>
                        <Flex direction="row" w="auto">
                            <Text as="b">Joining As{': '}</Text>

                            <CommonTooltip label={results.designation}>
                                <Text pl="5px">
                                    {getShortFormText(results.designation, 13)}
                                </Text>
                            </CommonTooltip>
                        </Flex>
                    </Flex>
                </Box>
            </Box>
        );
    };

    return (
        <Box>
            <Box overflowX="auto" fontSize="14px">
                <Table
                    variant="simple"
                    w={['100%', '100%', '100%', '100%', '80%']}
                >
                    <CommonTableHeading tableHeadings={TABLE_HEADINGS} />

                    <Tbody>
                        <Tr>
                            <Td border="none">
                                <Flex>
                                    {getPersonalInfoLeftSideData()}
                                    <Spacer />
                                    {getPersonalInfoRightSideData()}
                                </Flex>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </Box>
        </Box>
    );
};

PersonalInfo.propTypes = {
    results: PropTypes.object.isRequired,
    partnerList: PropTypes.array.isRequired,
    supervisor: PropTypes.object.isRequired,
    isEmployeeEditModalOpen: PropTypes.bool.isRequired,
    setEmployeeEditModalOpen: PropTypes.func.isRequired,
};

export default PersonalInfo;
