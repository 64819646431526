import React from 'react';

import { Box, Flex, Table, Tbody } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';

import CommonTableHeading from '../../common/CommonTableHeading';
import IndividualLeaveReportTableRow from './IndividualLeaveReportTableRow';

const TABLE_HEADINGS = ['Leave Date', 'Total Leave', 'Leave Type', 'Reason'];

const EmployeeIndividualLeaveResults = ({ results, startDate, endDate }) => {
    const { content: leaveDetails } = results;
    const { leaves } = leaveDetails;

    return (
        <Flex mt="8px" direction="column" fontSize="12px">
            <Box mb={2}>
                {'Showing result for '}
                <b>
                    {startDate} - {endDate}
                </b>
            </Box>
            <Box overflow="hidden">
                <Box overflow="auto">
                    <Table variant="simple" justify="center" align="center">
                        <CommonTableHeading tableHeadings={TABLE_HEADINGS} />
                        <Tbody>
                            {leaves.map((leave, index) => {
                                return (
                                    <IndividualLeaveReportTableRow
                                        key={index}
                                        result={leave}
                                        index={index}
                                    />
                                );
                            })}
                        </Tbody>
                    </Table>
                </Box>
            </Box>
        </Flex>
    );
};

EmployeeIndividualLeaveResults.propTypes = {
    results: PropTypes.object.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
};

export default EmployeeIndividualLeaveResults;
