import React, { useState } from 'react';

import {
    Box,
    Flex,
    HStack,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    SimpleGrid,
    Spacer,
    Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import cross from '../../../assets/cross.svg';
import * as color from '../../../constants/colorCode';
import { setAllLeaveDateAndType } from '../../../helper/set-all-leave-date-and-type';
import Calendar from './Calendar';

function AllLeaveModal({ isOpen, onClose, wholeCalender, leaveInfo }) {
    const [allLeaveTypeAndLeaveDate] = useState(
        setAllLeaveDateAndType(leaveInfo)
    );
    const wholeCalendarKeys = Object.keys(wholeCalender);
    const wholeCalendarValues = Object.values(wholeCalender);

    const getWholeCalendarView = () => {
        return (
            <>
                <Flex>
                    <Spacer />
                    <Image onClick={onClose} pt={5} src={cross} pr="10px" />
                </Flex>
                <SimpleGrid columns={[1, 1, 2, 3, 4]}>
                    {wholeCalendarValues.map((calendar, index) => {
                        return (
                            <Box pb={index <= 7 ? '50px' : '20px'} key={index}>
                                <Calendar
                                    results={calendar}
                                    allLeaveTypeAndLeaveDate={
                                        allLeaveTypeAndLeaveDate
                                    }
                                    month={wholeCalendarKeys[index]}
                                />
                            </Box>
                        );
                    })}
                </SimpleGrid>
            </>
        );
    };

    const getFooterItemsHighter = (text, colorCode) => {
        return (
            <HStack spacing={3}>
                <Box bg={colorCode} borderRadius="50%" px={2} py={2}></Box>
                <Text>{text}</Text>
            </HStack>
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered={false}
            closeOnOverlayClick={true}
        >
            <ModalOverlay />
            <ModalCloseButton />
            <ModalContent
                size="lg"
                maxW={{ lg: '85%', base: '96%' }}
                bgColor="#FFF"
                color="#464646"
                border="1px solid #EEEEEE"
            >
                <ModalBody pt={30}>{getWholeCalendarView()}</ModalBody>
                <ModalFooter mr="20px" mb="20px">
                    <HStack spacing={5}>
                        {getFooterItemsHighter('Sick', color.SICK_LEAVE_COLOR)}
                        {getFooterItemsHighter(
                            'Annual',
                            color.ANNUAL_LEAVE_COLOR
                        )}
                        {getFooterItemsHighter(
                            'Casual',
                            color.CASUAL_LEAVE_COLOR
                        )}
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

AllLeaveModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    wholeCalender: PropTypes.object.isRequired,
    leaveInfo: PropTypes.object.isRequired,
};

export default AllLeaveModal;
