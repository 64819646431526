import React from 'react';

import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

function TeamMemberDeleteModal({ isOpen, onClose, onDelete }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent
                size="lg"
                h={{ lg: '210px', md: '180px', sm: '180px', base: '180px' }}
                position="relative"
                maxW={{ lg: '500px', md: '500px', sm: '500px', base: '350px' }}
                bgColor="#FFF"
                color="#464646"
                align="center"
            >
                <ModalBody pt={{ lg: '30px', base: '5px' }}>
                    <Text
                        mt={25}
                        fontSize={{ lg: '18px', base: '16px' }}
                        mx={{ lg: '30px', base: 'auto' }}
                    >
                        Do you want to delete the team member permanently?
                    </Text>
                    <Flex mt={30} direction="row" justifyContent="center">
                        <Button
                            border="1px solid"
                            borderColor="#9A9A9A"
                            color="#9A9A9A"
                            mr={6}
                            w={24}
                            h={8}
                            bgColor="#FFFFFF"
                            _hover={{
                                color: '#FFFFFF !important',
                                bg: '#9A9A9A !important',
                            }}
                            onClick={onClose}
                        >
                            NO
                        </Button>
                        <Button
                            w={24}
                            h={8}
                            border="1px solid"
                            bgColor="#FFFFFF"
                            borderColor="#0077C0"
                            color="#0077C0"
                            onClick={onDelete}
                            _hover={{
                                color: '#FFFFFF !important',
                                bg: '#0077C0 !important',
                            }}
                        >
                            YES
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

TeamMemberDeleteModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default TeamMemberDeleteModal;
