export const EMPLOYEE_DESIGNATION_CHARACTER_LIMIT = 35;
export const EMPLOYEE_NAME_CHARACTER_LIMIT = 25;
export const EMPLOYEE_LEAVE_DETAILS_CHARACTER_LIMIT = 30;

export const EmployeeStatus = [
    'STAGE1',
    'STAGE2',
    'CONSULTANT',
    'CONTRACTUAL',
    'PERMANENT',
    'TERMINATED',
];
