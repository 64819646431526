export const breadcrumbData = [
    { key: 'Home', value: '/employees/0/10' },
    { key: 'Employee Management', value: '/employees/0/10' },
    { key: 'Add Employee', value: '/employee/add' },
    { key: 'Employee Details', value: '/employee/details/0' },
    { key: 'Team Management', value: '/teams/0/10' },
    { key: 'Team Details', value: '/team/details/0' },
    { key: 'Add Admin', value: '/admin/add' },
    { key: 'Reports', value: '' },
    { key: 'Leave Reports', value: '/leaves/0/10' },
    { key: 'Details', value: '' },
];

export const ADMIN_BREADCRUMB = ['Home', 'Add Admin'];

export const EMPLOYEE_LIST_BREADCRUMB = ['Home', 'Employee Management'];
export const EMPLOYEE_DETAILS_BREADCRUMB = [
    'Home',
    'Employee Management',
    'Employee Details',
];
export const ADD_EMPLOYEE_BREADCRUMB = [
    'Home',
    'Employee Management',
    'Add Employee',
];

export const TEAM_LIST_BREADCRUMB = ['Home', 'Team Management'];
export const TEAM_DETAILS_BREADCRUMB = [
    'Home',
    'Team Management',
    'Team Details',
];

export const LEAVE_LIST_BREADCRUMB = ['Home', 'Reports', 'Leave Reports'];
export const LEAVE_DETAILS_BREADCRUMB = [
    'Home',
    'Reports',
    'Leave Reports',
    'Details',
];
