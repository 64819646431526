import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import GoogleLogin from '@caranmegil/react-google-login';
import { Box, Button, Circle, Flex, Text, useToast } from '@chakra-ui/react';
import { gapi } from 'gapi-script';

import {
    IS_TOKEN_FOUND,
    SET_GOOGLE_LOGIN_TOKEN_ID,
    SET_LAST_LOGIN_TIME,
    SET_LOGIN_TYPE,
} from '../../constants/actionTypeConstant';
import { ACCESS_TOKEN_KEY } from '../../constants/commonConstant';
import {
    LOGIN_PATH,
    buildEmployeesPath,
} from '../../constants/pathNameConstants';
import { useInformation } from '../../contexts/informationContext';
import LoginManagement from '../../service/login-management';

const GoogleLoginComponent = () => {
    const { dispatch } = useInformation();
    const navigate = useNavigate();
    const toast = useToast();

    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId: clientId,
                scope: 'https://www.googleapis.com/auth/contacts',
            });
        };
        gapi.load('client', initClient);
    }, []);

    const responseSuccessGoogle = response => {
        dispatch({
            type: SET_GOOGLE_LOGIN_TOKEN_ID,
            payload: response.tokenId,
        });
        //dispatch({ type: 'IS_TOKEN_FOUND', payload: true });
        googleLoginHandler(response.tokenId);
    };

    const googleLoginHandler = tokenId => {
        const loginHandler = async payload => {
            const response = await LoginManagement.adminLogin(payload);
            localStorage.setItem(ACCESS_TOKEN_KEY, response.token);
            dispatch({ type: SET_LAST_LOGIN_TIME, payload: new Date() });
            navigate(buildEmployeesPath(0, 10), { replace: true });
        };

        if (tokenId) {
            dispatch({ type: IS_TOKEN_FOUND, payload: true });
            const payload = {
                token: tokenId,
                loginType: getLoginType(tokenId),
            };

            loginHandler(payload).catch(error => {
                localStorage.removeItem(ACCESS_TOKEN_KEY);
                dispatch({ type: IS_TOKEN_FOUND, payload: false });
                dispatch({ type: SET_LOGIN_TYPE, payload: '' });
                dispatch({
                    type: SET_GOOGLE_LOGIN_TOKEN_ID,
                    payload: null,
                });

                toast({
                    title: 'Login Failed',
                    description: error.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
                navigate(LOGIN_PATH, { replace: true });
            });
        }
    };

    const getLoginType = tokenId => {
        if (tokenId) {
            dispatch({ type: SET_LOGIN_TYPE, payload: 'GOOGLE' });
            return 'GOOGLE';
        } else return null;
    };

    return (
        <Flex
            bgColor="#0077C0"
            h="100vh"
            justifyContent="center"
            alignItems="center"
        >
            <Box
                bg="#FFF"
                w={{ lg: '450px', md: '450px', base: '95%' }}
                h="400px"
            >
                <Box direction="column" align="center" py={50} fontSize="24px">
                    <Circle
                        py={6}
                        bgColor="#0077C0"
                        fontSize="81px"
                        fontWeight="900"
                        color="#FFFFFF"
                        w="85px"
                        h="85px"
                        align="center"
                    >
                        A
                    </Circle>
                    <Text color="#333333">Aladin</Text>

                    <Text
                        color="#333333"
                        pt="30px"
                        fontSize={['18px', '20px', '20px']}
                    >
                        {'Sign in through'}
                    </Text>

                    <Flex
                        mt="10px"
                        direction="column"
                        justifyContent="center"
                        align="center"
                    >
                        <Button mt="10px" w="0px" bg="#FFF">
                            <GoogleLogin
                                clientId={clientId}
                                buttonText="Google Login"
                                onSuccess={responseSuccessGoogle}
                                cookiePolicy={'single_host_origin'}
                                isSignedIn={false}
                            />
                        </Button>
                    </Flex>
                </Box>
            </Box>
        </Flex>
    );
};

export default GoogleLoginComponent;
