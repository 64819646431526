import React from 'react';

import { Td, Tr } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';

import * as color from '../../constants/colorCode';
import { TABLE_FIELD_PADDING } from '../../constants/commonConstant';
import { getDateRangeInShortForm, getDuration } from '../../helper/utils';

const DURATION_TYPE = 'day';

const IndividualLeaveReportTableRow = ({ result, index }) => {
    return (
        <Tr bgColor={index & 1 ? color.ODD_ROW_COLOR : color.EVEN_ROW_COLOR}>
            <Td p={TABLE_FIELD_PADDING} fontSize="12px">
                {getDateRangeInShortForm(result.from, result.to)}
            </Td>
            <Td p={TABLE_FIELD_PADDING}>
                {getDuration(result.duration, DURATION_TYPE)}
            </Td>
            <Td p={TABLE_FIELD_PADDING}>{result.leaveType}</Td>
            <Td p={TABLE_FIELD_PADDING}>{result.reason}</Td>
        </Tr>
    );
};

IndividualLeaveReportTableRow.propTypes = {
    result: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
};

export default IndividualLeaveReportTableRow;
