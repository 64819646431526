import Service from './index';

const ADMIN_SEARCH_PATH = 'admin/';

export default class AdminManagement {
    static async addNewAdmin(payload) {
        return Service.post(`${ADMIN_SEARCH_PATH}`, payload).then(
            response => response.data
        );
    }
}
