import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Flex, Image, Spacer, Text, useToast } from '@chakra-ui/react';

import addTeamIcon from '../../assets/icons/addIcon.svg';
import CommonPagination from '../../common/CommonPagination';
import CustomBreadcrumb from '../../common/CustomBreadcrumb';
import DataLoader from '../../common/DataLoader';
import PageTitle from '../../common/PageTitle';
import {
    SET_TEAM_MEMBERS,
    SET_TEAMS,
} from '../../constants/actionTypeConstant';
import { TEAM_LIST_BREADCRUMB } from '../../constants/breadcrumbData';
import {
    buildEmployeesPath,
    buildTeamDetailsPath,
    buildTeamsPath,
} from '../../constants/pathNameConstants';
import { useInformation } from '../../contexts/informationContext';
import TeamManagement from '../../service/team-management';
import TeamAddModal from '../components/TeamAddModal';
import TeamTableResults from '../components/TeamTableResults';

export default function TeamList() {
    const navigate = useNavigate();
    const { state, dispatch } = useInformation();
    const toast = useToast();
    let { page, size } = useParams();
    const [results, setResults] = useState(state.teamsResult);
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);
    const [isOpenTeamAddingModal, setIsOpenTeamAddingModal] = useState(false);

    const PAGE_TITLE = 'Team List';

    const submit = async values => {
        setDataLoaderOpen(true);
        try {
            await TeamManagement.addTeam(values);
            setDataLoaderOpen(false);
            setIsOpenTeamAddingModal(false);
            toast({
                title: 'Successfully Added',
                description: 'Team has been added successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            navigate(buildTeamsPath(0, 10));
        } catch (error) {
            setDataLoaderOpen(false);
            setIsOpenTeamAddingModal(false);
            toast({
                title: 'Add fail',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    async function navigateToTeamDetails(teamResult) {
        setDataLoaderOpen(true);
        try {
            const response = await TeamManagement.findAllTeamMembers(
                teamResult.id
            );
            const teamDetails = {
                members: response.members,
                teamId: teamResult.id,
                teamName: teamResult.name,
                teamDescription: teamResult.description,
            };
            dispatch({ type: SET_TEAM_MEMBERS, payload: teamDetails });
            setDataLoaderOpen(false);
            setIsOpenTeamAddingModal();
            navigate(buildTeamDetailsPath(teamResult.id));
        } catch (error) {
            setDataLoaderOpen(false);
            toast({
                title: 'Search Fail',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }

    useEffect(() => {
        fetchTeamsData();

        return () => {
            dispatch({ type: SET_TEAMS, payload: { content: [] } });
        };
    }, [page, size]);

    async function fetchTeamsData() {
        setDataLoaderOpen(true);
        try {
            const response = await TeamManagement.findTeams(page, size);
            setResults(response);
            setDataLoaderOpen(false);
            dispatch({ type: 'SET_TEAMS', payload: response });
            navigate(buildTeamsPath(page, size));
        } catch (error) {
            setDataLoaderOpen(false);
            toast({
                title: 'Search Fail',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            navigate(buildEmployeesPath(0, 10));
        }
    }

    function setPageNumber(pageNumber) {
        page = pageNumber;
        navigate(buildTeamsPath(page, size));
    }

    if (!results || isDataLoaderOpen)
        return <DataLoader isOpen={isDataLoaderOpen} />;

    if (isOpenTeamAddingModal) {
        return (
            <TeamAddModal
                isOpen={isOpenTeamAddingModal}
                onClose={() => setIsOpenTeamAddingModal(false)}
                onSubmit={submit}
            />
        );
    }

    return (
        <Box>
            <Box bgColor="#FFFFFF">
                <CustomBreadcrumb allBreadcrumbData={TEAM_LIST_BREADCRUMB} />

                <Flex direction="row">
                    <PageTitle title={PAGE_TITLE} />

                    <Spacer />

                    <Flex my="16px">
                        <Box
                            align="right"
                            mx={[4, 8, 8]}
                            fontSize="16px"
                            fontWeight="400"
                        >
                            <Flex
                                direction="row"
                                cursor="pointer"
                                onClick={() => setIsOpenTeamAddingModal(true)}
                            >
                                <Image mx={2} src={addTeamIcon} />
                                <Text> New Team </Text>
                            </Flex>
                        </Box>
                    </Flex>
                </Flex>

                <TeamTableResults
                    results={results}
                    navigateToTeamDetails={navigateToTeamDetails}
                />
                <CommonPagination
                    page={page}
                    size={size}
                    totalPages={results.totalPages}
                    setPageNo={setPageNumber}
                />
            </Box>
        </Box>
    );
}
