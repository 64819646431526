import { Navigate } from 'react-router-dom';

import DataLoader from '../../common/DataLoader';
import { ACCESS_TOKEN_KEY } from '../../constants/commonConstant';
import { buildEmployeesPath } from '../../constants/pathNameConstants';
import { useInformation } from '../../contexts/informationContext';
import GoogleLoginComponent from '../components/GoogleLoginComponent';

const AuthContainer = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    const {
        state: { isTokenFound },
    } = useInformation();

    if (accessToken === null && isTokenFound) {
        return <DataLoader isOpen={true} />;
    } else if (accessToken) {
        return <Navigate to={buildEmployeesPath(0, 10)} replace={true} />;
    } else return <GoogleLoginComponent />;
};

export default AuthContainer;
