const getColorForDate = type => {
    if (type === 'SICK') return '#FF0000';
    else if (type === 'UNPAID') return '#0077C0';
    else return '#00C058';
};

export const setAllLeaveDateAndType = datas => {
    let obj = [];
    const currentYear = new Date().getFullYear();
    const leaveDetails = datas.details;

    for (let i = 0; i < leaveDetails.length; i++) {
        const outerDetails = leaveDetails[i];
        let rangeDurationReason = outerDetails.details;
        let payload = {
            year: currentYear,
            type: outerDetails.type,
            color: getColorForDate(outerDetails.type),
        };
        for (let j = 0; j < rangeDurationReason.length; j++) {
            const innerDetails = rangeDurationReason[j];
            let from = new Date(innerDetails.from);
            let to = new Date(innerDetails.to);
            if (from.getMonth() === to.getMonth()) {
                let left = from.getDate();
                let right = to.getDate();

                for (let k = left; k <= right; k++) {
                    payload = {
                        ...payload,
                        date: k,
                        month: from.getMonth(),
                    };
                    obj.push(payload);
                }
            } else {
                let left = from.getDate();
                let right = from.getDate();

                for (let k = left; k <= 31; k++) {
                    payload = {
                        ...payload,
                        date: k,
                        month: from.getMonth(),
                    };
                    obj.push(payload);
                }
                for (let k = 1; k <= right; k++) {
                    payload = {
                        ...payload,
                        date: k,
                        month: to.getMonth(),
                    };
                    obj.push(payload);
                }
            }
        }
    }
    return obj;
};
