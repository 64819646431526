import React from 'react';

import { Box, Flex, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import CommonTableHeading from '../../common/CommonTableHeading';

const TeamMembersTable = ({ members, setEmployeeId, setDeleteModalOpen }) => {
    const TABLE_HEADINGS = ['Name of the Employee', 'Role', ''];
    return (
        <>
            <Box overflowX="auto" fontSize="12px">
                <Table variant="simple" w="100%">
                    <CommonTableHeading tableHeadings={TABLE_HEADINGS} />

                    <Tbody>
                        {members.map((member, index) => (
                            <Tr
                                key={member.employeeId}
                                bgColor={index & 1 ? '#EEEEEE' : '#FFFFFF'}
                            >
                                <Td
                                    p={3}
                                    minW={['250px', '260px', '280px']}
                                    w={['250px', '260px', '280px']}
                                >
                                    {member.name}
                                </Td>
                                <Td p={3}>{member.role}</Td>
                                <Td p={3}>
                                    <Flex direction="row" color="#0077C0">
                                        <Text
                                            cursor="pointer"
                                            onClick={() => {
                                                setEmployeeId(
                                                    member.employeeId
                                                );
                                                setDeleteModalOpen(true);
                                            }}
                                        >
                                            {' '}
                                            Delete{' '}
                                        </Text>
                                        <Text
                                            cursor="pointer"
                                            ml="32px"
                                            _hover={{
                                                as: 'u !important',
                                            }}
                                        >
                                            {' '}
                                            Edit{' '}
                                        </Text>
                                    </Flex>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </>
    );
};

TeamMembersTable.propTypes = {
    members: PropTypes.object.isRequired,
    setEmployeeId: PropTypes.func.isRequired,
    setDeleteModalOpen: PropTypes.func.isRequired,
};
export default TeamMembersTable;
