import React from 'react';
import ReactApexChart from 'react-apexcharts';

import {
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

function AverageWorkingHourModal({ isOpen, onClose, attendanceBarData }) {
    const MODAL_TITLE_FOR_AVERAGE_WORKING_HOURS = 'Average working hour';
    const MODAL_TITLE_FOR_WORKING_HOURS = 'Working hour';
    const MODAL_CONTENT_WIDTH = { lg: '700px', base: '96%' };
    const MODAL_CONTENT_BOX_WIDTH = {
        lg: '270px',
        md: '45%',
        sm: '45%',
        base: '40%',
    };

    if (attendanceBarData === null) return null;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered={true}
            //closeOnOverlayClick={false}
            bgColor="#EEE"
        >
            <ModalCloseButton />
            <ModalContent
                size="lg"
                maxW={MODAL_CONTENT_WIDTH}
                bgColor="#FFF"
                color="#464646"
                align="center"
                justifyContent="center"
                border="8px solid"
                borderColor="#0077C0"
            >
                <Box bg="#0077C0" h="8px" w="100.5%">
                    <Box
                        ml={MODAL_CONTENT_BOX_WIDTH}
                        w="80px"
                        bgColor="#FFFFFF"
                        borderRadius="10px"
                        border="2px solid #FFFFFF"
                    ></Box>
                </Box>
                <ModalHeader>
                    {attendanceBarData.options.dimension === 'Day' ? (
                        <Text>{MODAL_TITLE_FOR_WORKING_HOURS}</Text>
                    ) : (
                        <Text>{MODAL_TITLE_FOR_AVERAGE_WORKING_HOURS}</Text>
                    )}
                </ModalHeader>
                <ModalBody pt={0}>
                    <ReactApexChart
                        options={attendanceBarData.options}
                        series={attendanceBarData.series}
                        type="bar"
                        height={350}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

AverageWorkingHourModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    attendanceBarData: PropTypes.object.isRequired,
};

export default AverageWorkingHourModal;
