import React from 'react';

import { Avatar, Flex, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const EmployeeDetails = ({ results }) => {
    const API_URL = process.env.REACT_APP_SERVER_URL;
    const { content } = results;
    const { designation, name, profilePictureUrl, supervisorName } = content;

    if (content === null) return null;
    return (
        <Flex direction="row" pt="10px">
            <Avatar
                w={{ lg: '53px', base: '45px' }}
                h={{ lg: '53px', base: '45px' }}
                name={name}
                src={`${API_URL}${profilePictureUrl}`}
            />
            <Flex direction="column" ml="20px">
                <Text fontSize="22px" lineHeight="30px" color="#4F4F4F">
                    {name}
                </Text>
                <Text fontSize="16px" lineHeight="25px" color="#333333">
                    {designation}
                </Text>
                <Text fontSize="12px" lineHeight="20px" color="#000000">
                    <b>Supervisor:</b> {supervisorName}{' '}
                </Text>
            </Flex>
        </Flex>
    );
};

EmployeeDetails.propTypes = {
    results: PropTypes.object.isRequired,
};

export default EmployeeDetails;
