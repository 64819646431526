import { months } from '../constants/commonConstant';

export const isLeapYear = year => {
    return year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0);
};

export const getHumanReadableDate = (date, isShortFormMonth = true) => {
    const [year, month, day] = date.split('-');

    let newDay = Number(day);
    let newMonth = months.find(m => Number(m.key) === Number(month)).value;
    let newYear = year;
    if (isShortFormMonth) newMonth = newMonth.slice(0, 3);
    return `${newMonth} ${newDay}, ${newYear}`;
};

export const currentDate = () => {
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (day <= 9) day = '0' + day;
    if (month <= 9) month = '0' + month;
    return `${year}-${month}-${day}`;
};
export const oneMonthLessThanCurrentMonth = () => {
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    if (month === 1) {
        day = 1;
    } else {
        if (day > 28) day = 28;
        month -= 1;
    }
    let year = date.getFullYear();
    if (day <= 9) day = '0' + day;
    if (month <= 9) month = '0' + month;
    return `${year}-${month}-${day}`;
};

export const startingFromCurrentYear = () => {
    let year = new Date().getFullYear();
    return `${year}-01-01`;
};

export const getShortFormDate = (date, dayFirst = false) => {
    let [, month, day] = date.split('-');
    let monthFirstThreeLetter = months
        .find(m => Number(m.key) === Number(month))
        .value.slice(0, 3);

    if (dayFirst)
        return `${String(day).padStart(2, '0')} ${monthFirstThreeLetter}`;
    return `${monthFirstThreeLetter} ${day}`;
};

export const makeHeadersFirstLetterCapitalOnly = heading => {
    let headerFirstLetter = heading.slice(0, 1);
    let headerRestLetter = heading.slice(1).toLowerCase();

    return `${headerFirstLetter}${headerRestLetter}`;
};

export const getAllPartnerNameInString = partnerObj => {
    let partnersName = '';
    for (let i = 0; i < partnerObj.length; i++) {
        if (partnersName.length > 0) partnersName += ',';
        partnersName += partnerObj[i].name;
    }
    return partnersName;
};

export const getDateRangeInShortForm = (from, to) => {
    const fromDate = getShortFormDate(from, true);
    const toDate = getShortFormDate(to, true);
    const currentYear = new Date(to).getFullYear();
    return `${fromDate} - ${toDate} ${currentYear}`;
};

export const getDuration = (duration, type = 'day') => {
    return duration === 1
        ? `01 ${type}`
        : `${String(duration).padStart(2, '0')} ${type}s`;
};
