import React from 'react';

import { Box, Flex, Image, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';

import clock from '../../../assets/icons/clock.svg';
import CommonTableHeading from '../../../common/CommonTableHeading';

const TABLE_WIDTH = ['100%', '100%', '100%', '100%', '80%'];
const AVERAGE_WORKING_HOURS_WIDTH = ['250px', '300px', '350px', '500px'];
const HOURS_DURATION_WIDTH = ['300px', '300px', '350px', '400px'];

const AttendanceChart = ({ attendances, setAverageWorkingHourModalOpen }) => {
    const TABLE_HEADINGS = ['Name', 'Details'];

    const getInHoursUI = avgWorkingMinutes => {
        let hours = parseInt(avgWorkingMinutes / 60);
        let minutes = avgWorkingMinutes % 60;
        if (isNaN(hours)) hours = 0;
        if (isNaN(minutes)) minutes = 0;

        let content = `${hours} hour`;
        content += hours > 1 ? 's' : '';
        content += ` ${minutes} minute`;
        content += minutes > 1 ? 's' : '';
        return <Text>{content}</Text>;
    };

    const getAverageWorkingHours = () => {
        return (
            <Td colSpan={2} border="none">
                <Flex direction="row" justifyContent="space-between">
                    <Box></Box>

                    <Flex
                        onClick={() => setAverageWorkingHourModalOpen(true)}
                        cursor="pointer"
                    >
                        <Text>{'View Total Working Hour'}</Text>
                        <Image ml={2} mt={1} w="14px" h="14px" src={clock} />
                    </Flex>
                </Flex>
            </Td>
        );
    };

    return (
        <React.Fragment>
            <Box mb={20}>
                <Box overflowX="auto" fontSize="12px">
                    <Table variant="simple" w={TABLE_WIDTH}>
                        <CommonTableHeading tableHeadings={TABLE_HEADINGS} />
                        <Tbody>
                            <Tr>
                                <Td
                                    minW={AVERAGE_WORKING_HOURS_WIDTH}
                                    w={AVERAGE_WORKING_HOURS_WIDTH}
                                >
                                    {'Average working hours'}
                                </Td>
                                {/* TODO: for how many days */}
                                <Td
                                    minW={HOURS_DURATION_WIDTH}
                                    w={HOURS_DURATION_WIDTH}
                                >
                                    {getInHoursUI(
                                        attendances.averageWorkingTimeInMinutes
                                    )}
                                </Td>
                            </Tr>
                            <Tr>{getAverageWorkingHours()}</Tr>
                        </Tbody>
                    </Table>
                </Box>
            </Box>
        </React.Fragment>
    );
};

AttendanceChart.propTypes = {
    attendances: PropTypes.object.isRequired,
    setAverageWorkingHourModalOpen: PropTypes.func.isRequired,
};

export default AttendanceChart;
