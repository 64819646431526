import React from 'react';
import * as IoIcons from 'react-icons/io';

import { Image } from '@chakra-ui/react';

import employee from '../assets/menubarIcon/employeeIcon.svg';
import leaveReport from '../assets/menubarIcon/leaveReportIcon.svg';
import report from '../assets/menubarIcon/reportIcon.svg';
import team from '../assets/menubarIcon/teamIcon.svg';

const ICON_SIZE = 24;
const MENUBAR_DATA = [
    {
        label: 'Employee Management',
        lShow: 'Employee',
        path: '/employees/0/10',
        icon: <Image src={employee} alt="employee icon" size={ICON_SIZE} />,
    },
    {
        label: 'Team Management',
        lShow: 'Team',
        path: '/teams/0/10',
        icon: <Image src={team} alt="employee icon" size={ICON_SIZE} />,
    },
    {
        label: 'Reports',
        lShow: 'Reports',
        path: '',
        child: [
            {
                label: 'Leave Report',
                lShow: 'Leave',
                path: '/leaves/0/10',
                icon: <Image src={leaveReport} size={ICON_SIZE} />,
            },
        ],
        icon: <Image src={report} size={ICON_SIZE} />,
    },
    {
        label: 'Admin Management',
        lShow: 'Admin',
        path: '/admin/add',
        icon: <IoIcons.IoMdPerson size={ICON_SIZE} />,
    },
];

export default MENUBAR_DATA;
