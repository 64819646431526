import axios from 'axios';

import { ACCESS_TOKEN_KEY } from '../constants/commonConstant';

const API_URL = process.env.REACT_APP_SERVER_URL;

const Service = axios.create({
    baseURL: API_URL,
    headers: {
        Accept: '*/*',
    },
});

Service.interceptors.request.use(config => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (token) {
        config.headers.token = token;
    }
    return config;
});

Service.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const err = error.response;
        return Promise.reject({
            status: err.status,
            message: err.data.message || '',
        });
    }
);

export default Service;
