import Service from './index';

const TEAM_SEARCH_PATH = 'admin/team';

export default class TeamManagement {
    static async findTeams(page = 0, size = 10) {
        const params = new URLSearchParams({
            page: page,
            size: size,
        }).toString();

        return Service.get(`${TEAM_SEARCH_PATH}/all?${params}`).then(
            response => response.data
        );
    }

    static async findAllTeamMembers(teamId) {
        const params = new URLSearchParams({
            teamId: teamId,
        }).toString();

        return Service.get(`${TEAM_SEARCH_PATH}/member/all?${params}`).then(
            response => response.data
        );
    }

    static async addTeam(payload) {
        return Service.post(`${TEAM_SEARCH_PATH}`, payload).then(
            response => response.data
        );
    }

    static async addTeamMember(payload) {
        return Service.post(`${TEAM_SEARCH_PATH}/member`, payload).then(
            response => response.data
        );
    }

    static async deleteTeamMember(teamId, employeeId) {
        const params = new URLSearchParams({
            teamId: teamId,
            employeeId: employeeId,
        }).toString();

        return Service.delete(`${TEAM_SEARCH_PATH}/member?${params}`).then(
            response => response.data
        );
    }
}
