import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useParams } from 'react-router-dom';

import { Box, Flex, Spacer, Text, useToast } from '@chakra-ui/react';

import CommonPagination from '../../common/CommonPagination';
import CustomBreadcrumb from '../../common/CustomBreadcrumb';
import DataLoader from '../../common/DataLoader';
import DownloadListTab from '../../common/DownloadListTab';
import PageTitle from '../../common/PageTitle';
import { LEAVE_DETAILS_BREADCRUMB } from '../../constants/breadcrumbData';
import { makePieGraphForLeave } from '../../helper/leaveReport/pie-chart-for-leave';
import {
    currentDate,
    getDateRangeInShortForm,
    getDuration,
    startingFromCurrentYear,
} from '../../helper/utils';
import ReportManagement from '../../service/reports-management';
import EmployeeDetails from '../components/EmployeeDetails';
import EmployeeIndividualLeaveResults from '../components/EmployeeIndividualLeaveResults';
import LeaveCharts from '../components/LeaveCharts';
import LeaveReportDateFilter from '../components/LeaveReportDateFilter';

const PAGE_TITLE = 'Leave Details';

const EmployeeLeaveDetails = () => {
    const { id } = useParams();
    const toast = useToast();
    const [leavesResult, setLeavesResult] = useState(null);
    const [dateRange, setDateRange] = useState([
        startingFromCurrentYear(),
        currentDate(),
    ]);
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);
    const [leavePieData, setLeavePieData] = useState({
        series: [],
        options: {},
    });
    const [csvData, setCsvData] = useState(false);
    const [page, setPage] = useState(0);
    const size = 10;
    const csvInstance = useRef();

    const onDateRangeChangeHandler = dateRange => {
        setDateRange(dateRange);
    };

    const setPageNumber = pageNumber => {
        setPage(pageNumber);
    };

    useEffect(() => {
        if (csvData && csvInstance.current && csvInstance.current.link) {
            csvInstance.current.link.click();
            setCsvData(false);
        }
    }, [csvData]);

    const exportCsv = async () => {
        try {
            const leaves = await ReportManagement.individualLeaveReportDownload(
                id,
                dateRange[0],
                dateRange[1]
            );
            const leaveResultObject = [];
            leaves.forEach(({ from, to, duration, leaveType, reason }) => {
                leaveResultObject.push({
                    ['Date Range']: getDateRangeInShortForm(from, to),
                    ['Duration']: getDuration(duration),
                    ['Leave Type']: leaveType,
                    ['Reason']: reason,
                });
            });
            setCsvData(leaveResultObject);
        } catch (error) {
            setCsvData([]);
        }
    };

    const getIndividualLeaveDetails = async () => {
        setDataLoaderOpen(true);
        try {
            const response =
                await ReportManagement.individualLeaveReportDetails(
                    dateRange[0],
                    dateRange[1],
                    page,
                    size,
                    id
                );
            setLeavesResult(response);
            const leaveData = response.content;
            const dataForMakingPieChart = [
                {
                    leaveType: 'Casual Leave',
                    leaveCount: leaveData.casualLeave,
                },
                {
                    leaveType: 'Sick Leave',
                    leaveCount: leaveData.sickLeave,
                },
                {
                    leaveType: 'Unpaid Leave',
                    leaveCount: leaveData.unPaidLeave,
                },
            ];
            setLeavePieData(makePieGraphForLeave(dataForMakingPieChart));
            setDataLoaderOpen(false);
        } catch (error) {
            toast({
                title: 'Export Failed',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };
    useEffect(() => {
        getIndividualLeaveDetails();
    }, [id, dateRange, page]);

    if (leavesResult === null) {
        return <DataLoader isOpen={isDataLoaderOpen} />;
    }

    return (
        <Box>
            <CustomBreadcrumb allBreadcrumbData={LEAVE_DETAILS_BREADCRUMB} />

            <Flex direction="row">
                <PageTitle title={PAGE_TITLE} />

                <Spacer />
                <DownloadListTab exportCsv={exportCsv} />

                {csvData ? (
                    <CSVLink
                        uFEFF={false}
                        data={csvData}
                        filename={`aladdin-leaves-report-${id}.csv`}
                        className="hidden"
                        ref={csvInstance}
                        target="_blank"
                    />
                ) : null}

                <Flex
                    my="16px"
                    direction={{ lg: 'row', md: 'row', base: 'column' }}
                >
                    <LeaveReportDateFilter
                        onChange={onDateRangeChangeHandler}
                    />
                </Flex>
            </Flex>
            <Box>
                <EmployeeDetails results={leavesResult} />
                <LeaveCharts
                    results={leavesResult}
                    leavePieData={leavePieData}
                />
            </Box>
            <EmployeeIndividualLeaveResults
                results={leavesResult}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
            />
            {leavesResult.totalPages > 1 && (
                <CommonPagination
                    totalPages={leavesResult.totalPages}
                    setPageNo={setPageNumber}
                    page={page}
                    size={size}
                />
            )}
            <Box mt={2} mb={7}>
                <Text fontSize="12px" fontWeight="300">
                    <b>
                        {leavesResult.content
                            ? leavesResult.content.totalLeaveTaken
                            : 0}
                    </b>
                    {' Days Total Leave Taken'}
                </Text>
            </Box>
        </Box>
    );
};

export default EmployeeLeaveDetails;
