import React from 'react';
import ReactApexChart from 'react-apexcharts';

import { Box, Flex, Spacer, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { plannedAndUplannedBarWidth } from '../../helper/leaveReport/decide-planned-unplanned-bar-width';

const whiteBoxCss = {
    my: '20px',
    px: '10px',
    bg: '#FFFFFF',
    w: 'full',
    h: '205px',
    radius: '4px',
    border: '1px solid rgba(189, 189, 189, 0.3)',
    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.07)',
};

const LeaveCharts = ({ results, leavePieData }) => {
    const { content } = results;
    const { companyAverage, planLeave, totalLeaveTaken, unPlanLeave } = content;

    // deciding planned and unplanned bar width
    const { planLeaveBarWidth, unplanLeaveBarWidth, averageLeaveBarWidth } =
        plannedAndUplannedBarWidth(planLeave, unPlanLeave, totalLeaveTaken);

    const getComparisonCheckpoints = (leave, color) => {
        return (
            <Box display="inline-block">
                <Flex direction="row" ml="10px">
                    <Box w="8px" h="8px" bg={color} />
                    <Text
                        fontWeight={300}
                        fontSize="10px"
                        lineHeight="10px"
                        ml="5px"
                    >
                        {leave}
                    </Text>
                </Flex>
            </Box>
        );
    };

    if (content.length === 0) return null;

    const getFractionalBarArea = (
        color,
        leaveTypeBarWidth,
        leaveType,
        leaveTypeValue
    ) => {
        return (
            <Flex
                direction="column"
                bg={color}
                h={`${leaveTypeBarWidth}%`}
                align="center"
                justify="center"
            >
                <Text align="center" justifyContent="center">
                    {leaveType}
                </Text>
                <b>{String(leaveTypeValue).padStart(2, '0')} days</b>
            </Flex>
        );
    };

    return (
        <Flex direction={['column', 'column', 'column', 'row']}>
            <Box {...whiteBoxCss}>
                <Flex justify="center">
                    <Text
                        fontWeight={600}
                        fontSize="14px"
                        color="#000000"
                        mt="10px"
                    >
                        Leave Type
                    </Text>
                    <Spacer />
                    <Box w="380px">
                        <ReactApexChart
                            options={leavePieData.options}
                            series={leavePieData.series}
                            type="donut"
                        />
                    </Box>
                    <Spacer />
                    <Box />
                </Flex>
            </Box>

            <Box mx="10px" />

            <Box {...whiteBoxCss}>
                <Text
                    fontWeight={600}
                    fontSize="14px"
                    color="#000000"
                    mt="10px"
                >
                    Consumed Leave Comparison
                </Text>
                <Flex align="center" justify="center" mt="10px">
                    <Flex h="100%">
                        <Box w="71px" h="128px" fontSize="10px">
                            {unPlanLeave > 0 ? (
                                getFractionalBarArea(
                                    '#E59D9D',
                                    unplanLeaveBarWidth,
                                    'Unplanned',
                                    unPlanLeave
                                )
                            ) : (
                                <Box
                                    w="71px"
                                    h={`${unplanLeaveBarWidth}%`}
                                ></Box>
                            )}
                            {planLeave > 0 &&
                                getFractionalBarArea(
                                    '#96CEF8',
                                    planLeaveBarWidth,
                                    'Planned',
                                    planLeave
                                )}
                        </Box>
                        <Box w="71px" h="128px" fontSize="10px" ml="10px">
                            <Box bg="#FFFFFF" h="14%" />
                            {getFractionalBarArea(
                                '#FFF597',
                                averageLeaveBarWidth,
                                'Company Average',
                                companyAverage
                            )}
                        </Box>
                    </Flex>
                </Flex>
                <Flex direction="row" py="10px" justify="center">
                    {getComparisonCheckpoints('Planned Leave', '#96CEF8')}
                    {getComparisonCheckpoints('Unplanned Leave', '#E59D9D')}
                    {getComparisonCheckpoints('Average Leave', '#FFF597')}
                </Flex>
            </Box>
        </Flex>
    );
};

LeaveCharts.propTypes = {
    results: PropTypes.object.isRequired,
    leavePieData: PropTypes.object.isRequired,
};

export default LeaveCharts;
