import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Flex, Image, Spacer, Text, useToast } from '@chakra-ui/react';

import addMemberIcon from '../../assets/icons/addMemberIcon.svg';
import CustomBreadcrumb from '../../common/CustomBreadcrumb';
import DataLoader from '../../common/DataLoader';
import PageTitle from '../../common/PageTitle';
import { SET_TEAM_MEMBERS } from '../../constants/actionTypeConstant';
import { TEAM_DETAILS_BREADCRUMB } from '../../constants/breadcrumbData';
import { buildTeamsPath } from '../../constants/pathNameConstants';
import { useInformation } from '../../contexts/informationContext';
import EmployeeManagement from '../../service/employee-management';
import TeamManagement from '../../service/team-management';
import TeamMemberAddModal from '../components/TeamMemberAddModal';
import TeamMemberDeleteModal from '../components/TeamMemberDeleteModal';
import TeamMembersTable from '../components/TeamMembersTable';

const TeamDetails = () => {
    const PAGE_TITLE = 'Team Details';

    const { state, dispatch } = useInformation();
    const {
        teamDetails: { members, teamId, teamName, teamDescription },
    } = state;
    const navigate = useNavigate();
    const toast = useToast();
    const [teamMembers, setTeamMembers] = useState(members);
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [employeeId, setEmployeeId] = useState();
    const [isTeamMemberAddModalOpen, setIsTeamMemberAddModalOpen] =
        useState(false);

    const teamMembersSetCallBack = val => {
        setTeamMembers(val);
    };
    const handleDeleteTeamMember = async (
        teamId,
        employeeId,
        teamName,
        teamDescription
    ) => {
        setDataLoaderOpen(true);
        try {
            await TeamManagement.deleteTeamMember(teamId, employeeId);
            const response = await TeamManagement.findAllTeamMembers(teamId);
            const payload = {
                members: response.members,
                teamId,
                teamName,
                teamDescription,
            };
            setTeamMembers(response.members);
            dispatch({ type: SET_TEAM_MEMBERS, payload });
            setDataLoaderOpen(false);
            setDeleteModalOpen(false);
            toast({
                title: 'Successfully Delete',
                description: 'Member has been deleted successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            setDataLoaderOpen(false);
            setDeleteModalOpen(false);
            toast({
                title: 'Deletion fail',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    // Only for mapping Employee name with email
    useEffect(() => {
        const fetchEmployee = async (index, employeeId) => {
            const response = await EmployeeManagement.getEmployeeDetails(
                employeeId
            );
            let newTeamMembers = teamMembers;

            for (let j = 0; j < newTeamMembers.length; j++) {
                if (j === index) {
                    newTeamMembers[j] = {
                        ...newTeamMembers[j],
                        name: response.name,
                    };
                }
            }
            setTeamMembers(newTeamMembers);

            dispatch({
                type: SET_TEAM_MEMBERS,
                payload: {
                    members: teamMembers,
                    teamId: teamId,
                    teamName: teamName,
                    teamDescription: teamDescription,
                },
            });
        };
        for (let i = 0; i < teamMembers.length; i++) {
            fetchEmployee(i, teamMembers[i].employeeId).catch(error => {
                toast({
                    title: 'Members fetching fail',
                    description: error.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
                navigate(buildTeamsPath(0, 10));
            });
        }
    }, [teamMembers]);

    if (isDataLoaderOpen) return <DataLoader isOpen={isDataLoaderOpen} />;

    if (isTeamMemberAddModalOpen) {
        return (
            <TeamMemberAddModal
                isOpen={isTeamMemberAddModalOpen}
                onClose={() => setIsTeamMemberAddModalOpen(false)}
                teamMembersSetCallBack={teamMembersSetCallBack}
            />
        );
    }
    return (
        <>
            <TeamMemberDeleteModal
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onDelete={() => {
                    handleDeleteTeamMember(
                        teamId,
                        employeeId,
                        teamName,
                        teamDescription
                    );
                }}
            />

            <CustomBreadcrumb allBreadcrumbData={TEAM_DETAILS_BREADCRUMB} />

            <Box
                w={{ lg: '650px', base: '100%' }}
                ml={{ lg: '20%', base: 0 }}
                mt={10}
            >
                <Box direction="row" my="16px" align="left">
                    <PageTitle title={PAGE_TITLE} />
                </Box>

                <Box
                    direction="column"
                    mt="32px"
                    mx={{ lg: '24px', md: '24px', sm: '24px', base: '8px' }}
                >
                    <Text as="b" color="#0077C0">
                        {teamName}
                    </Text>
                    <Text color="#333333">{teamDescription}</Text>
                </Box>

                <Box
                    direction="column"
                    my="25px"
                    mx={{ lg: '24px', base: '0px' }}
                    pb="64px"
                >
                    <hr />

                    <Flex direction="row" my="16px" mx="8px">
                        <Text>Team Members</Text>
                        <Spacer />
                        <Box align="right" fontSize="16px" fontWeight="400">
                            <Flex
                                direction="row"
                                cursor="pointer"
                                onClick={() =>
                                    setIsTeamMemberAddModalOpen(true)
                                }
                            >
                                <Image mx={2} src={addMemberIcon} />
                                <Text color="#828282"> Add Team Member </Text>
                            </Flex>
                        </Box>
                    </Flex>

                    <TeamMembersTable
                        members={teamMembers}
                        setDeleteModalOpen={setDeleteModalOpen}
                        setEmployeeId={setEmployeeId}
                    />
                </Box>
            </Box>
        </>
    );
};

export default TeamDetails;
