import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Select,
    Spacer,
    Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import PropTypes from 'prop-types';

import { TeamStatus } from '../../constants/teamManagementConstant';
import { addTeamValidation } from '../../validation/dataValidation';

function TeamAddModal({ isOpen, onClose, onSubmit }) {
    const [teamStatus, setTeamStatus] = useState(TeamStatus[0]);

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: 'all',
        resolver: yupResolver(addTeamValidation),
    });

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent
                maxW={{ lg: '550px', md: '500px', sm: '500px', base: 'full' }}
                mx={2}
                bgColor="#FFF"
                color="#464646"
                align="center"
            >
                <ModalCloseButton />
                <ModalBody>
                    <Flex pt={8} direction="column" fontSize="14px">
                        <Text as="b" fontSize="24px">
                            {' '}
                            Create New Team{' '}
                        </Text>

                        <Box my="16px" width={{ base: '96%', lg: '486px' }}>
                            <hr />
                        </Box>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl pt={2} isInvalid={errors.name}>
                                <FormLabel
                                    fontSize="14px"
                                    htmlFor="teamName"
                                    color="#464646"
                                >
                                    Name
                                </FormLabel>
                                <Flex direction={{ lg: 'row', base: 'column' }}>
                                    <Flex
                                        direction={{
                                            lg: 'column',
                                            base: 'column',
                                        }}
                                        width={{ base: '96%', lg: '486px' }}
                                    >
                                        <Input
                                            {...register('name')}
                                            placeholder="Name"
                                            bgColor="white"
                                            color="#464646"
                                            width="100%"
                                            border="1px"
                                            borderColor="#DDDDDD"
                                            _focus={{
                                                borderColor:
                                                    '#DDDDDD !important',
                                            }}
                                            onChange={e =>
                                                register('name').onChange(e)
                                            }
                                        />

                                        <Flex>
                                            <FormErrorMessage>
                                                {errors.name &&
                                                    errors.name.message}
                                            </FormErrorMessage>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </FormControl>

                            <FormControl pt={6}>
                                <FormLabel
                                    fontSize="14px"
                                    htmlFor="TeamDescription"
                                    color="#464646"
                                >
                                    Description (OPTIONAL)
                                </FormLabel>
                                <Flex direction={{ lg: 'row', base: 'column' }}>
                                    <Flex
                                        direction={{
                                            lg: 'column',
                                            base: 'column',
                                        }}
                                        width={{ base: '96%', lg: '486px' }}
                                    >
                                        <Input
                                            {...register('description')}
                                            placeholder="description"
                                            bgColor="white"
                                            color="#464646"
                                            width="100%"
                                            border="1px"
                                            borderColor="#DDDDDD"
                                            _focus={{
                                                borderColor:
                                                    '#DDDDDD !important',
                                            }}
                                            onChange={e =>
                                                register(
                                                    'description'
                                                ).onChange(e)
                                            }
                                        />
                                    </Flex>
                                </Flex>
                            </FormControl>

                            <FormControl mt={6}>
                                <FormLabel
                                    fontSize="14px"
                                    htmlFor="TeamStatus"
                                    color="#464646"
                                >
                                    Status
                                </FormLabel>
                                <Flex direction={{ lg: 'row', base: 'column' }}>
                                    <Flex
                                        direction={{
                                            lg: 'column',
                                            base: 'column',
                                        }}
                                        width={{ base: '96%', lg: '486px' }}
                                    >
                                        <Select
                                            {...register('status')}
                                            value={teamStatus}
                                            border="1px"
                                            borderColor="#DDDDDD"
                                            color="#464646"
                                            isInvalid={errors.status}
                                            _focus={{
                                                borderColor:
                                                    '#DDDDDD !important',
                                            }}
                                            _placeholder={{
                                                color: '#DDDDDD !important',
                                            }}
                                            onChange={e => {
                                                setTeamStatus(e.target.value);
                                            }}
                                        >
                                            {TeamStatus.map((value, index) => (
                                                <option
                                                    value={value}
                                                    key={index}
                                                >
                                                    {value}
                                                </option>
                                            ))}
                                        </Select>
                                    </Flex>
                                </Flex>
                                {errors.status && (
                                    <Text
                                        color="#E53E3E"
                                        mt={2}
                                        fontSize="14px"
                                        maxWidth={{ base: '96%', lg: '70%' }}
                                        status="error"
                                    >
                                        {errors.status && errors.status.message}
                                    </Text>
                                )}
                            </FormControl>

                            <Flex
                                direction="row"
                                mt={6}
                                mb="54px"
                                w={{ lg: '486px', base: '96%' }}
                            >
                                <Text as="u" cursor="pointer" onClick={onClose}>
                                    Cancel
                                </Text>
                                <Spacer />
                                <Button
                                    onSubmit={onSubmit}
                                    w="119px"
                                    h="30px"
                                    bgColor="#0077C0"
                                    color="white"
                                    type="submit"
                                    disabled={!isValid}
                                    _focus={{
                                        borderColor: 'none',
                                    }}
                                    _hover={{
                                        color: 'white',
                                        bgColor: '#0077C0',
                                    }}
                                    _disabled={{
                                        cursor: 'not-allowed',
                                        bgColor: '#DDDDDD !important',
                                        color: '#9A9A9A !important',
                                    }}
                                >
                                    Create
                                </Button>
                            </Flex>
                        </form>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

TeamAddModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default TeamAddModal;
