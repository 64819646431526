import { memo, useEffect, useState } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import DateRangePopover from '../../../common/DateRangePopover';

const EmployeeDateRangePicker = ({
    heading,
    startDefaultDate,
    endDefaultDate,
    startDateOnChange,
    endDateOnChange,
    getDetails,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const open = () => setIsOpen(!isOpen);
    const close = () => setIsOpen(false);

    useEffect(() => {
        if (!isOpen) {
            getDetails();
        }
    }, [isOpen, startDefaultDate, endDefaultDate]);

    const printDate = date => {
        const [year, month, day] = date.split('-');
        return `${day}.${month}.${year}`;
    };

    return (
        <Flex
            direction={['column', 'row']}
            lineHeight="16px"
            fontSize="14px"
            ml={[2, 2, 2, 2, 0]}
            my={4}
            pt="6px"
        >
            <Flex direction="row">
                <Flex pt="4px">
                    <Text as="b">
                        {heading} {' - '}
                    </Text>
                    <Text ml="4px">{printDate(startDefaultDate)}</Text>
                    <Text px="2px">{'-'}</Text>
                    <Text>{printDate(endDefaultDate)}</Text>
                </Flex>

                <Box px="5px">
                    <DateRangePopover
                        startDate={startDefaultDate}
                        endDate={endDefaultDate}
                        startDateChangeHandler={startDateOnChange}
                        endDateChangeHandler={endDateOnChange}
                        isOpen={isOpen}
                        open={open}
                        close={close}
                    />
                </Box>
            </Flex>
        </Flex>
    );
};

EmployeeDateRangePicker.propTypes = {
    heading: PropTypes.string.isRequired,
    startDateOnChange: PropTypes.func.isRequired,
    endDateOnChange: PropTypes.func.isRequired,
    startDefaultDate: PropTypes.string.isRequired,
    endDefaultDate: PropTypes.string.isRequired,
    getDetails: PropTypes.func.isRequired,
};

export default memo(EmployeeDateRangePicker);
